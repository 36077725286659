import { createContext, useContext } from "react";

export type IInitialData = Record<string, unknown>

/**
 * Контекст содержит начальный набор данных приложения.
 */
export const InitialDataContext = createContext<IInitialData>({})

/**
 * Возвращает начальный набор данных
 */
export const useInitialDataContext = () => useContext(InitialDataContext)
