import { EventProcessor } from '@hmm/core'
import { SubscriptionKey, keyToString } from './subscribtion'

export class SubscriptionStore {
  private subscriptions_keys: Map<string, SubscriptionKey> = new Map()
  private subscriptions: WeakMap<SubscriptionKey, EventProcessor[]> =
    new WeakMap()

  has(key: SubscriptionKey): boolean {
    const string_key = keyToString(key)
    return this.subscriptions_keys.has(string_key)
  }

  set(key: SubscriptionKey, processors: EventProcessor[]): void {
    const string_key = keyToString(key)
    const unified_key = this.subscriptions_keys.has(string_key)
      ? this.subscriptions_keys.get(string_key) || key
      : key
    this.subscriptions_keys.set(string_key, unified_key)
    this.subscriptions.set(unified_key, processors)
  }

  get(key: SubscriptionKey): EventProcessor[] | undefined {
    const has_direct = this.subscriptions.has(key)
    if (!has_direct && !this.subscriptions_keys.has(keyToString(key))) return
    if (has_direct) return this.subscriptions.get(key)
    const saved_key = this.subscriptions_keys.get(keyToString(key))
    if (!saved_key) return
    return this.subscriptions.get(saved_key)
  }

  delete(key: SubscriptionKey): void {
    this.subscriptions_keys.delete(keyToString(key))
  }

  getKeysIterator(): IterableIterator<SubscriptionKey> {
    return this.subscriptions_keys.values()
  }
}
