import { createContainer, Container } from 'brandi'
import { isBrowser } from '@hmm/utils'

import {
  Logger,
  LoggerConsole,
  LoggerBrowserConsole,
  LOGGER_TOKEN,
  LOGGER_WORKERS_TOKEN
} from 'modules/Logger'

import { EVENT_BUS, EventBus } from 'shared/event-bus'
import { EventBusTracker } from 'shared/event-tracker'
import { DATE_FORMATTER } from 'shared/date'
import { dateFormatter } from './dateFormatter'
import { API_CLIENT_TOKEN, ApiClient, StubApiClient } from 'modules/Api'
import { DOMAIN_EVENT_TRACKER } from '../services/TOKENS'
import { SpiderService, SPIDER } from 'modules/Spider'
import { API_TOKEN_STORAGE_TOKEN } from 'modules/Api/HmmApi'
import { SessionStorageAdapter, VariableStorage } from 'shared/storage'
import { SESSION_STORAGE } from 'application/storage/TOKENS'

export function bootstrap(): Container {
  const container = createContainer()
  const IS_BROWSER = isBrowser()

  /* init logger */
  container.bind(LOGGER_TOKEN).toInstance(Logger).inSingletonScope()
  container
    .bind(LOGGER_WORKERS_TOKEN)
    .toInstance(() => {
      return IS_BROWSER
        ? [new LoggerBrowserConsole(console)]
        : [new LoggerConsole(console)]
    })
    .inSingletonScope()

  /* init api client */
  container
    .bind(API_CLIENT_TOKEN)
    .toInstance(() =>
      IS_BROWSER ? new ApiClient(fetch.bind(window)) : new StubApiClient()
    )
    .inSingletonScope()

  IS_BROWSER
    ? container
        .bind(API_TOKEN_STORAGE_TOKEN)
        .toInstance(SessionStorageAdapter)
        .inSingletonScope()
    : container
        .bind(API_TOKEN_STORAGE_TOKEN)
        .toInstance(VariableStorage)
        .inTransientScope()

  /* init Domain Tracker */
  container
    .bind(DOMAIN_EVENT_TRACKER)
    .toInstance(EventBusTracker)
    .inSingletonScope()

  IS_BROWSER
    ? container
        .bind(SESSION_STORAGE)
        .toInstance(SessionStorageAdapter)
        .inSingletonScope()
    : container
        .bind(SESSION_STORAGE)
        .toInstance(VariableStorage)
        .inTransientScope()

  container.bind(EVENT_BUS).toInstance(EventBus).inSingletonScope()

  container.bind(DATE_FORMATTER).toConstant(dateFormatter)

  if(IS_BROWSER)
    container.bind(SPIDER).toInstance(SpiderService).inSingletonScope()

  return container
}
