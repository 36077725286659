import {
  BANNERS_CACHE,
  PROMOTIONS_CACHE,
  PROMOTIONS_PROVIDER,
  UserPromotionsModule
} from './di'
import { BannersCache, BannersRepository } from './BannersRepository'
import { PromotionsCache, PromotionsRepository } from './PromotionsRepository'
import { ILogger } from 'modules/Logger'
import { IReadableAPI } from 'shared/repository'
import { UnknownCreator } from 'brandi/lib/typings/types'
import { envType } from 'shared/environment'

type initParams = {
  promotions: {
    data: unknown
    cache: PromotionsCache
    provider: UnknownCreator<IReadableAPI>
  }
  banners: {
    data: unknown
    cache: BannersCache
  }
}

export function init(params: initParams, logger: ILogger, env: envType): void {
  logger.info('Init module', { service: 'UserPromotionsModule' })

  const bindings = [
    UserPromotionsModule.bind(BANNERS_CACHE).toInstance(() =>
      BannersRepository.fillCache(params.banners.data, params.banners.cache)
    ),
    UserPromotionsModule.bind(PROMOTIONS_CACHE).toInstance(() =>
      PromotionsRepository.fillCache(
        params.promotions.data,
        params.promotions.cache
      )
    ),
    UserPromotionsModule.bind(PROMOTIONS_PROVIDER).toInstance(
      params.promotions.provider
    )
  ]
  bindings.forEach((binding) =>
    env === 'client' ? binding.inResolutionScope() : binding.inTransientScope()
  )
}
