import { IKeyValueStorage } from 'modules/Storage'
import { BrowserStorage } from './BrowserStorage'

export class SessionStorageAdapter<T> implements IKeyValueStorage<T> {
  private readonly _storage: BrowserStorage<T>
  constructor() {
    this._storage = new BrowserStorage(sessionStorage)
  }

  del(key: string) {
    return this._storage.del(key)
  }

  get(key: string) {
    return this._storage.get(key)
  }

  has(key: string): boolean {
    return this._storage.has(key)
  }

  set(key: string, value: unknown): boolean {
    return this._storage.set(key, value)
  }
}
