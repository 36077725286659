/**
 * Преобразование строки в массив ключей
 * @param {String} key
 * @returns {String[]}
 */
function _keyToArray(key: string) {
    key = key.trim();
    if(key === '') {
        return [];
    }
    key = key.replace(/\[(\w+)\]/g, '.$1');
    key = key.replace(/^\./, '');
    return key.split('.');
}

/**
 * Получение значения свойства объекта
 * @param {Object} obj
 * @param {String} path Путь пропса ('prop.child[0].child')
 * @param {Boolean} [isAllowedProto=true] Разрешить доступ к __proto.
 * @returns {*|undefined}
 */
export function getProp (obj: any, path: string, isAllowedProto = true) {
    let keyParts = _keyToArray(path);
    let nestedVar = obj;

    while(keyParts.length > 0) {
        const childKey = keyParts.shift();
        const allowed = isAllowedProto || nestedVar.hasOwnProperty(childKey);

        if(allowed && childKey && nestedVar[childKey] !== undefined) {
            nestedVar = nestedVar[childKey];
        } else {
            return undefined;
        }
    }

    return nestedVar;
}

/**
 * Установка значения свойства объекта
 * @param {Object} obj
 * @param {String} path Строка пути пропса ('prop.child[0].child')
 * @param {*} value Присваиваемое значение
 */
export function setProp(obj: any, path: string, value: any) {
    if(typeof obj !== 'object' || typeof path !== 'string') {
        return;
    }

    const keyParts = _keyToArray(path);

    for(let i = 0; i < keyParts.length; i++) {
        const p = keyParts[i];
        if(i === keyParts.length - 1) {
            obj[p] = value;
            break;
        }
        if(typeof obj[p] !== 'object') {
            obj[p] = {};
        }
        obj = obj[p];
    }
}