import { ILogAdapter } from '../ports/out/ILogAdapter'
import { LogMessageDTO } from "modules/Logger"
import { LOG_PRIORITIES } from "modules/Logger"

export class LoggerBrowserConsole implements ILogAdapter {
  constructor(private readonly _console: Console) {}

  log(message: LogMessageDTO): void {
    const method = LoggerBrowserConsole._getConsoleMethod(message.priority)
    let logEntry: unknown[] = LoggerBrowserConsole._getLogEntryStub(
      message.block,
      message.id
    )
    if (Array.isArray(message.message)) {
      logEntry = logEntry.concat(message.message)
    } else {
      logEntry.push(message.message)
    }

    setTimeout(this._console[method].bind(this._console, ...logEntry))
  }

  private static _getLogEntryStub(
    block?: string | null,
    id?: string | number | null
  ) {
    return [
      `[%c${block}%c${id ? '.' + id : ''}%c]`,
      'color: blue',
      'color: grey',
      'color: black'
    ]
  }

  private static _getConsoleMethod(
    priority: LOG_PRIORITIES
  ): 'debug' | 'info' | 'warn' | 'error' | 'log' {
    switch (priority) {
      case LOG_PRIORITIES.debug:
        return 'debug'
      case LOG_PRIORITIES.info:
        return 'info'
      case LOG_PRIORITIES.warning:
        return 'warn'
      case LOG_PRIORITIES.error:
        return 'error'
      default:
        return 'log'
    }
  }
}
