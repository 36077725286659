import { injected } from 'brandi'
import { HmmPromotionsApi as Promotions } from './HmmPromotionsApi'
import { CustomerCartApi as CustomerCart } from './CustomerCartApi'
import { HMM_API_CONFIG_TOKEN } from './TOKENS'

export * from './CustomerCartApi'
export * from './CustomerOrdersApi'
export * from './HmmPromotionsApi'
export * from './FacetsApi'
export * from './HmmApi'
export * from './HmmApiFactory'
export * from './HmmCustomerAddressesApi'
export * from './TOKENS'

export const HmmPromotionsApi = injected(Promotions, HMM_API_CONFIG_TOKEN)
export const CustomerCartApi = injected(CustomerCart, HMM_API_CONFIG_TOKEN)
