import { token, injected, createDependencyModule } from 'brandi'
import { CustomerService, ICustomerRepository, IShippingAddressRepository } from '@hmm/core'
import {
  CurrentUserDataCache,
  CurrentUserCache,
  CurrentUserRepository,
} from './CurrentUser.repository'
import { PROMOTIONS_SERVICE } from 'modules/UserPromotions'

export const CurrentUserModule = createDependencyModule()

export const CUSTOMER_SERVICE = token<CustomerService>('CustomerService')
export const CUSTOMER_REPOSITORY = token<ICustomerRepository>(
  'ICustomerRepository'
)
export const CUSTOMER_DATA_CACHE = token<CurrentUserDataCache>(
  'CurrentUserDataCache'
)
export const CUSTOMER_CACHE = token<CurrentUserCache>('CurrentUserCache')
export const ADDRESSES_REPOSITORY = token<IShippingAddressRepository>('IShippingAddressRepository')

injected(CustomerService, CUSTOMER_REPOSITORY, PROMOTIONS_SERVICE, ADDRESSES_REPOSITORY)
injected(CurrentUserRepository, CUSTOMER_DATA_CACHE, CUSTOMER_CACHE)
