import { IKeyValueStorage, ITempStorage } from 'modules/Storage'

export class MapCache<T> implements ITempStorage<T>, IKeyValueStorage<T> {
  constructor(
    private readonly map: Map<string, T> = new Map(),
    readonly timeout?: number
  ) {}

  del(key: string) {
    this.map.delete(key)
  }

  get(key: string) {
    return this.map.get(key)
  }

  has(key: string) {
    return this.map.has(key)
  }

  set(key: string, value: T, timeout?: number): void {
    this.map.set(key, value)
    if (timeout || this.timeout) {
      setTimeout(() => this.map.delete(key), timeout || this.timeout)
    }
  }
}
