import { Describe, type, string, optional, nullable, number } from 'superstruct'
import { assertContract } from 'contracts/assertContract'

export type DS_CurrentUser = {
  id: number
  role: string
  email?: string
  phone?: string
  first_name?: string | null
  middle_name?: string | null
  last_name?: string | null
  personal_promotion?: {
    title: string,
    id?: number
  }
}

export const current_user_contract: Describe<DS_CurrentUser> = type({
  id: number(),
  role: string(),
  email: optional(string()),
  phone: optional(string()),
  first_name: optional(nullable(string())),
  middle_name: optional(nullable(string())),
  last_name: optional(nullable(string())),
  personal_promotion: optional(type({
    title: string(),
    id: optional(number())
  }))
})

export const assertCurrentUserContract = (data: unknown) =>
  assertContract(data, current_user_contract)
