import { create, string } from 'superstruct'
import { nullableDefaulted } from 'shared/validation'
import { IConfigManager } from 'shared/config'
import { SpiderContext } from 'shared/spider'

export function getConfigValueMaybeAdmin(
  config: IConfigManager,
  ctx: SpiderContext,
  key: string
): string {
  const try_admin = Boolean(ctx.user.isAdminScope)
  if (try_admin) {
    const admin_value = create(
      config.get('ADMIN_' + key),
      nullableDefaulted(string(), '')
    )
    if (admin_value) return admin_value
  }
  return create(config.get(key), string())
}
