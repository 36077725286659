export interface IUploadableImage {
  [index: string]: unknown
  file: File
}

export class ImageApiDecorator {
  static create(data: IUploadableImage): FormData {
    const formData = new FormData()
    let meta: Record<string, unknown> = Object.assign({}, data)

    delete meta.file
    delete meta.url

    formData.append('files[]', data.file)
    formData.append('_files', JSON.stringify(meta))

    return formData
  }

  static async file2base64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === 'string') {
          resolve(e.target.result)
        } else {
          reject(e)
        }
      }
      reader.onerror = (e) => reject(e)
      reader.readAsDataURL(file)
    })
  }
}
