import {
  Describe,
  type,
  string,
  number,
  nullable,
  optional,
  boolean,
  array,
  object,
  record,
  unknown
} from 'superstruct'
import { banner_contract, DS_Banner } from 'contracts/Banner'
import { assertContract } from 'contracts/assertContract'

export type DS_Promotion = {
  id: number
  code: string
  layer: string
  title: string
  description: string | null
  enabled: boolean
  priority: number
  exclusive: boolean
  coupon_based: boolean
  created_at: number
  updated_at: number | null
  starts_at: number | null
  ends_at: number | null
  banners: DS_Banner[]
  amount?: {
    min: number
    max: number|null
  }
  actions?: Record<string, Record<string, unknown>>
}

export const promotion_contract: Describe<DS_Promotion> = type({
  id: number(),
  code: string(),
  layer: string(),
  title: string(),
  description: nullable(string()),
  enabled: boolean(),
  priority: number(),
  exclusive: boolean(),
  coupon_based: boolean(),
  created_at: number(),
  updated_at: nullable(number()),
  starts_at: nullable(number()),
  ends_at: nullable(number()),
  banners: array(banner_contract),
  amount: optional(object({ min: number(), max: nullable(number()) })),
  actions: optional(record(string(), record(string(), unknown())))
})

export const assertPromotionContract = (data: unknown) =>
  assertContract(data, promotion_contract)
