import { IApiClient } from "./ports/out/IApiClient";
import { param } from "../../utils/url/param";

export class ApiClient implements IApiClient {
  constructor(
    private readonly fetch: (
      input: RequestInfo,
      init?: RequestInit
    ) => Promise<Response>
  ) {}

  create(url: string, data?: BodyInit | null, headers?: HeadersInit, credentials?: RequestCredentials) {
    return this.fetch(ApiClient._getUrl(url), {
      method: 'POST',
      body: data,
      headers,
      credentials
    });
  }

  read(url: string, params?: Record<string, unknown>, headers?: HeadersInit, credentials?: RequestCredentials) {
    return this.fetch(ApiClient._getUrl(url, params), {
      headers,
      credentials
    });
  }

  update(url: string, data?: BodyInit | null, headers?: HeadersInit, credentials?: RequestCredentials) {
    return this.fetch(ApiClient._getUrl(url), {
      method: 'PUT',
      body: data,
      headers,
      credentials
    });
  }

  patch(url: string, data?: BodyInit | null, headers?: HeadersInit, credentials?: RequestCredentials) {
    return this.fetch(ApiClient._getUrl(url), {
      method: 'PATCH',
      body: data,
      headers,
      credentials
    });
  }

  delete(url: string, data?: BodyInit | null, headers?: HeadersInit, credentials?: RequestCredentials) {
    return this.fetch(ApiClient._getUrl(url), {
      method: 'DELETE',
      body: data,
      headers,
      credentials
    });
  }

  private static _getUrl(path: string, params?: Record<string, unknown>): string {
    const base = path.replace(/([^:]\/)\/+/gm, '$1');
    return params? base + '?' + ApiClient.serializeParams(params) : base;
  }

  static serializeParams(params: Record<string, unknown>): string {
    return param(params);
  }
}
