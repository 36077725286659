import { IConfigStorage } from '../ports/out/IConfigStorage'

export class ConfigBrowserStorage implements IConfigStorage {
  constructor(private readonly storage: Storage) {}

  get(key: string): unknown | null {
    return this.storage.getItem(key)
  }

  has(key: string): boolean {
    return this.storage.getItem(key) !== undefined
  }
}
