import { EventProcessor, EventShape, IEventBus, IEventSource } from '@hmm/core'
import { IChannel } from './IChannel'
import { LocalChannel } from './LocalChannel'
import { createEvent } from './event-factory'

export class EventBus implements IEventBus {
  constructor(private channels: IChannel[] = [new LocalChannel()]) {}

  async connect(channel: IChannel): Promise<void> {
    this.channels.push(channel)
  }

  subscribe(subscription: EventShape, processor: EventProcessor): () => void {
    this.channels.forEach((channel) => {
      channel.on(subscription, processor)
    })
    return () => this.unsubscribe(subscription, processor)
  }

  unsubscribe(subscription: EventShape, processor: EventProcessor): void {
    this.channels.forEach((channel) => {
      channel.off(subscription, processor)
    })
  }

  async notify(event_source: IEventSource): Promise<void> {
    const event = createEvent(event_source)
    this.channels.forEach((channel) => {
      channel.sendEvent(event)
    })
  }
}
