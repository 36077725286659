import { Describe, number, string, nullable, optional, type } from 'superstruct'
import { assertContract } from 'shared/contracts'

export type DS_CustomerAddress = {
  id?: number
  customer_id?: number
  first_name: string
  middle_name?: string | null
  last_name: string
  email: string | null
  phone: string | null
  post_code: string | null
  pvz_code?: string | null
  country_code: string
  country_title: string
  province: string | null
  locality: string
  street_address: string
  created_at?: number
  updated_at?: number
}

export const customer_address_contract: Describe<DS_CustomerAddress> = type({
  id: optional(number()),
  customer_id: optional(number()),
  first_name: string(),
  middle_name: optional(nullable(string())),
  last_name: string(),
  email: nullable(string()),
  phone: nullable(string()),
  post_code: nullable(string()),
  pvz_code: optional(nullable(string())),
  country_code: string(),
  country_title: string(),
  province: nullable(string()),
  locality: string(),
  street_address: string(),
  created_at: optional(number()),
  updated_at: optional(number())
})

export function assertCustomerAddressContract(data: unknown) {
  return assertContract(data, customer_address_contract)
}
