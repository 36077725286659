import { token, injected } from 'brandi'
import { HMM_API_CONFIG_TOKEN } from 'modules/Api/HmmApi'
import { AddressesAPIFactory } from './ports/out'
import { CustomerAddressesRepository } from './CustomerAddresses.repository'
import { apiFactory } from './api'

export const CUSTOMER_ADDRESSES_API_FACTORY = token<AddressesAPIFactory>(
  'AddressesAPIFactory'
)

injected(CustomerAddressesRepository, CUSTOMER_ADDRESSES_API_FACTORY)
injected(apiFactory, HMM_API_CONFIG_TOKEN)

