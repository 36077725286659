import { injected } from 'brandi'
import {
  IDomainEventTracker,
  IEventBus,
  IDomainEvent,
  IEventSource
} from '@hmm/core'
import { EVENT_BUS } from 'shared/event-bus'

/**
 * Транслирует доменные события в общую шину событий
 */
export class EventBusTracker implements IDomainEventTracker {
  constructor(private readonly event_bus: IEventBus) {}
  track(events: IDomainEvent[]): void {
    events.forEach((domain_event) => this.trackEvent(domain_event))
  }

  trackEvent(event: IDomainEvent) {
    this.event_bus.notify(EventBusTracker.convertDomainEvent(event))
  }

  static convertDomainEvent(domain_event: IDomainEvent): IEventSource {
    return {
      topic: domain_event.name,
      source: domain_event.domain,
      data: domain_event
    }
  }
}

injected(EventBusTracker, EVENT_BUS)
