import { IConfigStorage } from '../ports/out/IConfigStorage'

export class ConfigStorageVariable implements IConfigStorage {
  constructor(private readonly _data: Record<string, unknown>) {}

  has(option: string): boolean {
    return this._data.hasOwnProperty(option)
  }

  get(option: string) {
    if (this.has(option)) {
      return this._data[option] as unknown
    }

    return null
  }
}
