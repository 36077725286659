import { IEvent, EventShape, CounterIdGenerator } from '@hmm/core'

const identifier = new CounterIdGenerator()

export function createEvent<T extends EventShape = EventShape>(shape: T): IEvent {
  return Object.assign(
    {
      id: identifier.generateId(),
      timestamp: Date.now(),
      data: null
    },
    shape
  )
}
