import { declareEvent } from 'shared/spider/events/declareEvent'
import { User, Cart } from '../objects'

export type PageViewEventPayload = {
  user?: User
  cart?: Cart
  page: {
    type: string
    url: string
    path: string
    title: string
    queryString?: string
    referrer?: string
  }
}

/**
 * Событие просмотра страницы
 */
export const PageViewEvent = declareEvent<PageViewEventPayload>({
  subject: 'page',
  action: 'view'
})
