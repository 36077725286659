import { ApiResultDTO } from './ApiResultDTO'

export abstract class AbstractApi {
  protected _names: string[] = []
  constructor(
    /** Базовый url api */
    protected readonly _base: string = '/',
    /** Название api. Для логов */
    name?: string
  ) {
    if (name) {
      this._names.push(name)
    }
  }

  get name(): string {
    if (this._names.length) return this._names.join(' / ')
    return this._base
  }

  static async parseResponse(res: Response): Promise<[any, Response]> {
    const isJSON =
      res.headers.get(AbstractApi.CONTENT_TYPE) ===
      AbstractApi.JSON_HEADERS[AbstractApi.CONTENT_TYPE]
    const data = isJSON ? await res.json() : await res.text()

    return [data, res]
  }

  static resolveOrReject(apiResult: ApiResultDTO): Promise<ApiResultDTO> {
    if (apiResult.raw.ok) return Promise.resolve(apiResult)
    return Promise.reject(apiResult)
  }

  static formatResponse(parsed: [any, Response]): ApiResultDTO {
    const isObject = parsed[0] instanceof Object,
      data = isObject && parsed[0].data ? parsed[0].data : parsed[0],
      meta = isObject && parsed[0].meta ? parsed[0].meta : {}

    return new ApiResultDTO(parsed[1], data, meta)
  }

  static processResponse(res: Response): Promise<ApiResultDTO> {
    return AbstractApi.parseResponse(res)
      .then(AbstractApi.formatResponse)
      .then(AbstractApi.resolveOrReject)
  }

  static CONTENT_TYPE = 'Content-Type'
  static JSON_HEADERS = { [AbstractApi.CONTENT_TYPE]: 'application/json' }
}
