export enum INTEGRATIONS_NAMES {
  DATA_LAYER = 'DATA_LAYER',
  GTM = 'GTM',
  UNIVERSAL_ANALYTICS = 'UNIVERSAL_ANALYTICS',
  YANDEX_METRIKA = 'YANDEX_METRIKA',
  RETAIL_ROCKET = 'RETAIL_ROCKET',
  CARROTQUEST = 'CARROTQUEST',
  MYTARGET = 'MYTARGET',
  SENDSAY = 'SENDSAY',
  VK = 'VK'
}
