import { IPromotionRepository, PromotionEntity, Criteria } from '@hmm/core'
import { IKeyValueStorage } from 'modules/Storage'
import { assertPromotionContract, DS_Promotion } from 'contracts/Promotion'
import { merge, left, Either, right } from '@sweet-monads/either'
import { PromotionFactory } from 'factories/PromotionFactory'
import { ILogger } from 'modules/Logger'
import {
  InvalidApiResult,
  IApiResultPort,
  IReadableAPI
} from 'shared/repository'

export type PromotionsCache = IKeyValueStorage<DS_Promotion | string[]>
type PromotionCriteria = Criteria & { banner_id?: string; title?: string }
const ALL = 'all'
const service = 'PromotionsRepository'

export class PromotionsRepository implements IPromotionRepository {
  constructor(
    private readonly _provider: IReadableAPI,
    private readonly _cache: PromotionsCache,
    private readonly _logger: ILogger
  ) {}

  async getList(params?: PromotionCriteria): Promise<PromotionEntity[]> {
    const ids = this._cache.get(ALL)
    let cached: Either<unknown, PromotionEntity[]> = left(null)
    if (Array.isArray(ids)) {
      cached = this._getListFromCache(ids)
    }
    if (cached.isRight()) return cached.value

    return PromotionsRepository.getApiResultData(
      await this._provider.get(params)
    )
      .mapRight(PromotionsRepository.assertList)
      .join()
      .mapRight((v) => {
        PromotionsRepository.fillCache(v, this._cache)
        return v
      })
      .mapRight(PromotionsRepository.createList)
      .mapLeft((err) => {
        this._logger.error(err, { service })
        return []
      }).value
  }

  getListSync(
    params?: PromotionCriteria
  ): [PromotionEntity[], Error | undefined] {
    const ids = this._cache.get(ALL)
    let err: Error | undefined
    let promotions: PromotionEntity[] = []

    if (!Array.isArray(ids)) {
      this.getList(params)
      return [promotions, err] // Не понятно сущностей нет вообще, или нет в кеше
    }

    promotions = this._getListFromCache(ids, params).mapLeft((error) => {
      err = error
      this.getList(params)
      return []
    }).value

    return [promotions, err]
  }

  get(params?: PromotionCriteria): Promise<PromotionEntity | null> {
    throw new Error('Method not implemented.')
  }

  getSync(
    params?: PromotionCriteria
  ): [PromotionEntity | null, Error | undefined] {
    const [promotions, err] = this.getListSync(params)
    return [promotions.pop() || null, undefined]
  }

  private _getListFromCache(
    ids: string[],
    params?: PromotionCriteria
  ): Either<Error, PromotionEntity[]> {
    return PromotionsRepository.assertList(
      ids.map(this._cache.get.bind(this._cache))
    )
      .mapRight((v) => PromotionsRepository._filterData(v, params))
      .mapRight(PromotionsRepository.createList)
      .mapLeft((err) => {
        this._logger.error(err, { service })
        return err
      })
  }

  private static _filterData(data: DS_Promotion[], params?: PromotionCriteria) {
    const filters: Array<(data: DS_Promotion) => boolean> = []
    if (params?.banner_id)
      filters.push(
        (p) =>
          p.banners.findIndex(
            (banner) => String(banner.id) == params.banner_id
          ) > -1
      )

    if (params?.title) filters.push((p) => p.title === params.title)

    return filters.reduce((result, filter) => {
      return filter ? result.filter(filter) : result
    }, data)
  }

  static getApiResultData(apiResult: IApiResultPort) {
    if (!apiResult.data)
      return left(new InvalidApiResult('Api result without data'))
    return right(apiResult.data)
  }

  static assertList(data: unknown) {
    if (!Array.isArray(data)) return left(new Error('Data is not array'))
    return merge(data.map(PromotionsRepository.assertObject))
  }

  static assertObject(data: unknown) {
    return assertPromotionContract(data)
  }

  static createList(d: DS_Promotion[]) {
    return d.map(PromotionFactory.create)
  }

  static fillCache(
    data: unknown,
    cache: PromotionsCache,
    request?: string
  ): PromotionsCache {
    return PromotionsRepository.assertList(data)
      .mapRight((promotions) => {
        const ids: string[] = []
        promotions.forEach((promo) => {
          const id = String(promo.id)
          cache.set(id, promo)
          ids.push(id)
        })
        cache.set(request || ALL, ids)
        return cache
      })
      .mapLeft((_err) => {
        return cache
      }).value
  }
}
