import { IConfigStorage } from './ports/out/IConfigStorage'
import { IConfigManager } from 'shared/config'

/**
 * Предоставляет доступ к конфигурации приложения
 */
export class ConfigManager implements IConfigManager {
  /**
   * @param _sources Источники данных конфигурации в порядке приоритета. Если опция найдена в первом источнике, остальные не просматриваются
   */
  constructor(private readonly _sources: IConfigStorage[]) {}

  /**
   * Получает значение опции
   * @param optionName имя опции
   */
  get(optionName: string): unknown | null {
    const src = this._sources.find((source) => source.get(optionName) !== null)
    if (!src) return null

    return src.get(optionName)
  }
}
