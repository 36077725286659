import { CustomerService } from '@hmm/core'
import { ILogger } from 'modules/Logger'
import { envType } from 'shared/environment'
import {
  CustomerAddressesRepository,
  CUSTOMER_ADDRESSES_API_FACTORY,
  apiFactory
} from 'entities/CustomerAddress'
import {
  CurrentUserDataCache,
  CurrentUserCache
} from './CurrentUser.repository'
import {
  CurrentUserModule,
  CUSTOMER_CACHE,
  CUSTOMER_DATA_CACHE,
  CUSTOMER_SERVICE,
  CUSTOMER_REPOSITORY,
  ADDRESSES_REPOSITORY
} from './di'
import { CurrentUserRepository } from './CurrentUser.repository'

export function init(
  data: unknown,
  data_cache: CurrentUserDataCache,
  cache: CurrentUserCache,
  logger: ILogger,
  env: envType
): void {
  logger.info('Init module', { service: 'CurrentUserModule' })

  const bindings = [
    CurrentUserModule.bind(CUSTOMER_DATA_CACHE).toInstance(() =>
      CurrentUserRepository.fillCache(data, data_cache)
    ),
    CurrentUserModule.bind(CUSTOMER_SERVICE).toInstance(CustomerService),
    CurrentUserModule.bind(CUSTOMER_REPOSITORY).toInstance(
      CurrentUserRepository
    ),
    CurrentUserModule.bind(CUSTOMER_CACHE).toInstance(() => cache),
    CurrentUserModule.bind(ADDRESSES_REPOSITORY).toInstance(
      CustomerAddressesRepository
    ),
    CurrentUserModule.bind(CUSTOMER_ADDRESSES_API_FACTORY).toInstance(
      apiFactory
    )
  ]

  bindings.forEach((binding) =>
    env === 'client' ? binding.inSingletonScope() : binding.inTransientScope()
  )
}
