import { EventCategories, EventPriorities } from '../core'
import { Transaction } from '../objects'
import { declareEvent } from './declareEvent'

export type OrderCreateEventPayload = Transaction

export const OrderCreateEvent = declareEvent<OrderCreateEventPayload>({
  subject: 'order',
  action: 'create',
  category: EventCategories.ECOMMERCE,
  priority: EventPriorities.HIGH
})
