import { ISemanticEvent } from '../ports/in'

type GeneratorParams = Pick<
  ISemanticEvent<never>,
  'subject' | 'action' | 'object' | 'result'
>
export function getSemanticName(
  params: GeneratorParams,
  separator: string = '-'
): string {
  let result = params.subject + separator + params.action
  if (params.object) {
    result += separator + params.object
  } else if (params.result) {
    result += separator
  }
  if (params.result) {
    result += separator + params.result
  }
  return result
}
