import { IApiClient } from './ports/out/IApiClient'

export class StubApiClient implements IApiClient {
  create(_url: string, _data: BodyInit | null): Promise<Response> {
    return Promise.reject()
  }

  delete(_url: string, _data: BodyInit | null): Promise<Response> {
    return Promise.reject()
  }

  patch(_url: string, _data: BodyInit | null): Promise<Response> {
    return Promise.reject()
  }

  read(_url: string): Promise<Response> {
    return Promise.reject()
  }

  update(_url: string, _data: BodyInit | null): Promise<Response> {
    return Promise.reject()
  }
}
