import { HmmApi, HmmApiConfig } from "./HmmApi";

/**
 * @url https://bitbucket.org/web-research/handmademart-backend-v3/wiki/API_Shipping_Payment_Variants
 */
export class ShippingVariantsApi extends HmmApi {
  constructor(config: HmmApiConfig) {
    super(config);
    this._route.push('infrastructure', 'shipping', 'variants')
    this._names.push('Infrastructure', 'Shipping', 'Variants')
  }

  // @ts-ignore
  async get(params: { country_code: string, total_weight: number, declared_value: number, post_code?: string }) {
    return super.create(params);
  }
}
