import { DS_Banner } from 'contracts/Banner'
import { BannerEntity, ID } from '@hmm/core'

export class BannerFactory {
  static create(data: DS_Banner): BannerEntity {
    return BannerEntity.create({
      ...data,
      title: data.title || undefined,
      description: data.description || undefined,
      id: ID.persistent(data.id)
    })
  }
}
