import { left, right, Either } from '@sweet-monads/either'
import { CustomerEntity, ICustomerRepository } from '@hmm/core'
import { IKeyValueStorage } from 'modules/Storage'
import {
  DS_CurrentUser,
  assertCurrentUserContract
} from './CurrentUser.contract'
import { CurrentUserFactory } from './CurrentUserFactory'

export type CurrentUserDataCache = IKeyValueStorage<DS_CurrentUser>
export type CurrentUserCache = IKeyValueStorage<CustomerEntity>
export const CACHE_KEY = 'current_user'

export class CurrentUserRepository implements ICustomerRepository {
  constructor(
    private readonly _dataCache: CurrentUserDataCache,
    private readonly _cache: CurrentUserCache
  ) {}

  getPromotionData() {
    const data = this._dataCache.get(CACHE_KEY)
    return data?.personal_promotion || null
  }

  getSync(): [CustomerEntity | null, Error | undefined] {
    let entity: CustomerEntity | null = null
    let error: Error | undefined

    if (this._cache.has(CACHE_KEY))
      return [this._cache.get(CACHE_KEY) || null, error]

    if (!this._dataCache.has(CACHE_KEY)) return [entity, error]
    entity = CurrentUserRepository.createEntity(
      this._dataCache.get(CACHE_KEY) as DS_CurrentUser
    )
      .mapRight((entity) => {
        this._cache.set(CACHE_KEY, entity)
        return entity
      })
      .mapLeft((err) => {
        error = err
        return null
      }).value

    return [entity, error]
  }

  async get(): Promise<CustomerEntity | null> {
    const [entity] = this.getSync()
    return entity
  }

  static createEntity(data: DS_CurrentUser): Either<Error, CustomerEntity> {
    try {
      const entity = CurrentUserFactory.create(data)
      return right(entity)
    } catch (err) {
      return left(err as Error)
    }
  }

  static assertObject(data: unknown) {
    return assertCurrentUserContract(data)
  }

  static fillCache(
    data: unknown,
    cache: CurrentUserDataCache
  ): CurrentUserDataCache {
    return CurrentUserRepository.assertObject(data)
      .mapLeft((_) => {
        return cache
      })
      .mapRight((ds_current_user) => {
        cache.set(CACHE_KEY, ds_current_user)
        return cache
      }).value
  }
}
