import { useContext, createContext } from "react";

export type CSRFToken = string;

const context = createContext<CSRFToken>('')

export const CSRFTokenContextProvider = context.Provider

export function useCSRFToken(): CSRFToken {
  return useContext(context)
}
