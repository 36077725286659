import { ISemanticEvent, SpiderContext } from '../ports/in'
import { Integration } from '../ports/out'
import { EventBuffer } from './EventBuffer'

export class Spider {
  private readonly _buffer: EventBuffer<ISemanticEvent>
  private readonly ctx: SpiderContext
  constructor(
    bufferSize: number,
    ctx: SpiderContext,
    private _integrations: Integration[] = []
  ) {
    this._buffer = new EventBuffer<ISemanticEvent>(bufferSize)
    this.ctx = ctx
  }

  public addIntegration(integration: Integration): void {
    for (const event of this._buffer) {
      if (!event) continue
      integration.trackEvent(event, this.ctx)
    }
    this._integrations.push(integration)
  }

  public trackEvent(event: ISemanticEvent): void {
    this._integrations.forEach((integration) =>
      integration.trackEvent(event, this.ctx)
    )
    this._buffer.push(event)
  }

  static createContext(ctx: Partial<SpiderContext>): SpiderContext {
    return Object.assign(
      { user: { id: null }, cart: { count: 0 }, system: { currencyCode: '' } },
      ctx
    )
  }
}
