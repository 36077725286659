export type DS_Position = {
  postcode: string | null
  country_code: string
  country_title?: string
  province?: string
  locality?: string
  street_address?: string
  geopoint?: { latitude: number, longitude: number }
}

export class PositionDTO {
  constructor(
    readonly postcode: string | null,
    readonly country_code: string,
    readonly country_title?: string,
    readonly province?: string,
    readonly locality?: string,
    readonly street_address?: string,
    readonly geopoint?: { latitude: number, longitude: number }
  ) {}

  static create(data: DS_Position) {
    return new PositionDTO(
      data.postcode,
      data.country_code,
      data.country_title,
      data.province,
      data.locality,
      data.street_address,
      data.geopoint
    )
  }
}
