import { token, injected } from 'brandi'
import { FacetsApi } from './FacetsApi'
import { HmmApiConfig } from './HmmApi'
import { IKeyValueStorage } from 'modules/Storage'
import { HmmApiFactory } from './HmmApiFactory'
import { API_CLIENT_TOKEN } from 'modules/Api'
import { ExceptionLoggerPort } from './ports/out/exception-logger.port'
import { TimingCollectorPort } from './ports/out/timing-collector.port'
import { CustomerCartApi } from './CustomerCartApi'
import {ShippingVariantsApi} from "modules/Api/HmmApi/ShippingVariantsApi";
import { CustomerOrdersApi } from "modules/Api/HmmApi/CustomerOrdersApi";

export const HMM_API_URL_TOKEN = token<string>('API_URL')
export const API_TOKEN_STORAGE_TOKEN =
  token<IKeyValueStorage<string>>('ApiTokenStorage')
export const HMM_API_CONFIG_TOKEN = token<HmmApiConfig>('HmmApiConfig')
export const HMM_API_FACTORY_TOKEN = token<HmmApiFactory>('HmmApiFactory')
export const HMM_API_EXCEPTION_LOGGER =
  token<ExceptionLoggerPort>('ExceptionLogger')
export const HMM_API_TIMING_COLLECTOR =
  token<TimingCollectorPort>('TimingCollector')
export const FACETS_API_TOKEN = token<FacetsApi>('FacetsApi')
export const CUSTOMER_CART_API_TOKEN = token<CustomerCartApi>('CustomerCartApi')
export const SHIPPING_VARIANTS_API = token<ShippingVariantsApi>('ShippingVariantsApi')
export const CUSTOMER_ORDERS_API = token<CustomerOrdersApi>('CustomerOrdersApi')

injected(CustomerOrdersApi, HMM_API_CONFIG_TOKEN)
injected(ShippingVariantsApi, HMM_API_CONFIG_TOKEN)
injected(FacetsApi, HMM_API_CONFIG_TOKEN)
injected(CustomerCartApi, HMM_API_CONFIG_TOKEN)
injected(
  HmmApiFactory,
  HMM_API_URL_TOKEN,
  API_CLIENT_TOKEN,
  API_TOKEN_STORAGE_TOKEN,
  HMM_API_EXCEPTION_LOGGER.optional,
  HMM_API_TIMING_COLLECTOR.optional
)
