import { TSubscription, IEvent } from '@hmm/core'

const SEPARATOR = '|'

export type SubscriptionKey = [
  string | undefined,
  string | undefined,
  string | undefined
]

export function generateSubscriptionKey(shape: TSubscription): SubscriptionKey {
  let result: SubscriptionKey = [undefined, undefined, undefined]
  shape.source && (result[0] = shape.source)
  shape.topic && (result[1] = shape.topic)
  shape.target && (result[2] = shape.target)
  return result
}

export function matchEvent(event: IEvent, key: SubscriptionKey): boolean {
  const [source, topic, target] = key
  if (source === undefined && topic === undefined && target === undefined)
    return true
  if (event.target) {
    return topic
      ? topic === event.topic && event.target === target
      : event.target === target
  }
  if (source === undefined && topic === event.topic) return true
  if (source !== event.source) return false
  if (topic === undefined) return true
  return key[1] === event.topic
}

export function keyToString(key: SubscriptionKey): string {
  return key.join(SEPARATOR)
}
