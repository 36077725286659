import { IKeyValueStorage } from "modules/Storage";

export class BrowserStorage<T> implements IKeyValueStorage<T> {
  constructor(private readonly _storage: Storage) {}

  has(key: string) {
    return Boolean(this._storage.getItem(key))
  }

  get(key: string) {
    const raw = this._storage.getItem(key)
    if(raw === null) return null
    let parsed;
    try {
      parsed = JSON.parse(raw)
    } catch (_) {
      return null;
    }

    return parsed;
  }

  set(key: string, value: unknown): boolean {
    try {
      this._storage.setItem(key, JSON.stringify(value))
    } catch (_) {
      return false
    }
    return true;
  }

  del(key: string) {
    this._storage.removeItem(key)
  }

}
