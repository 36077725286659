type dataLayerEvent = Record<string, any>
const CHUNK_THRESHOLD = 25
const PURCHASE = 'purchase'
/**
 * Разбивает на чанки большие транзакции
 */
export function yandexDlPurchase(
  e: dataLayerEvent,
  yandexDL: dataLayerEvent[]
): dataLayerEvent {
  if (!e.event || e.event !== PURCHASE) return e
  if (
    !e.ecommerce ||
    !Array.isArray(e.ecommerce.items) ||
    e.ecommerce.items.length <= CHUNK_THRESHOLD
  )
    return e

  const items = e.ecommerce.items,
    total = items.length
  let chunk = 0,
    start = 0,
    step = CHUNK_THRESHOLD,
    end = start + step

  while (end < total) {
    yandexDL.push({
      event: PURCHASE,
      ecommerce: chunk
        ? {
            transaction_id: e.ecommerce.transaction_id + '_' + chunk,
            items: items.slice(start, end)
          }
        : Object.assign({}, e.ecommerce, {
            items: items.slice(start, end)
          })
    })
    start = end
    end = Math.min(start + step, total)
    chunk++
  }

  return {
    event: PURCHASE,
    ecommerce: {
      transaction_id: e.ecommerce.transaction_id + '_' + chunk,
      items: items.slice(start, end)
    }
  }
}
