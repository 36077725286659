import { EventCategories, EventPriorities } from '../core'
import { User } from '../objects'
import { declareEvent } from './declareEvent'

export type UserSubscribeEventPayload = {
  user: User
  email: string
}

/**
 * Пользователь подписался на рассылку
 */
export const UserSubscribeEvent = declareEvent<UserSubscribeEventPayload>({
  subject: 'user',
  action: 'subscribe',
  category: EventCategories.ECOMMERCE,
  priority: EventPriorities.NORMAL
})
