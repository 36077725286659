import { create, string } from 'superstruct'
import { nullableDefaulted } from 'shared/validation'
import { IConfigManager } from 'shared/config'

export function getDefaultCurrency(config: IConfigManager) {
  return create(
    config.get('DEFAULT_CURRENCY'),
    nullableDefaulted(string(), 'RUB')
  )
}
