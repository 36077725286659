import { ILogger, ILogParams } from './ports/in/ILogger'
import { ILogAdapter } from './ports/out/ILogAdapter'
import { LogMessageDTO } from './LogMessageDTO'
import { LOG_PRIORITIES } from './log-priorities'

export class Logger implements ILogger {
  constructor(private _workers: ILogAdapter[]) {}

  addWorker(worker: ILogAdapter) {
    this._workers.push(worker)
  }

  debug(...messages: unknown[]): void {
    const params: ILogParams = Logger.isLogParams(messages[messages.length - 1])? messages.pop() as ILogParams: {}
    this._pushMessage(
      new LogMessageDTO(
        messages,
        LOG_PRIORITIES.debug,
        params.service || params.component,
        params.id
      )
    )
  }

  info(...messages: unknown[]): void {
    const params: ILogParams = Logger.isLogParams(messages[messages.length - 1])? messages.pop() as ILogParams: {}
    this._pushMessage(
      new LogMessageDTO(
        messages,
        LOG_PRIORITIES.info,
        params.service || params.component,
        params.id
      )
    )
  }

  log(...messages: unknown[]): void {
    const params: ILogParams = Logger.isLogParams(messages[messages.length - 1])? messages.pop() as ILogParams: {}

    this._pushMessage(
      new LogMessageDTO(
        messages,
        LOG_PRIORITIES.log,
        params.service || params.component,
        params.id
      )
    )
  }

  warn(...messages: unknown[]): void {
    const params: ILogParams = Logger.isLogParams(messages[messages.length - 1])? messages.pop() as ILogParams: {}

    this._pushMessage(
      new LogMessageDTO(
        messages,
        LOG_PRIORITIES.warning,
        params.service || params.component,
        params.id
      )
    )
  }

  error(...messages: unknown[]): void {
    const params: ILogParams = Logger.isLogParams(messages[messages.length - 1])? messages.pop() as ILogParams: {}

    this._pushMessage(
      new LogMessageDTO(
        messages,
        LOG_PRIORITIES.error,
        params.service || params.component,
        params.id
      )
    )
  }

  private _pushMessage(message: LogMessageDTO) {
    this._workers.forEach((worker) => worker.log(message))
  }

  isLogParams(param: unknown) {
    return Logger.isLogParams(param)
  }

  static isLogParams(param: unknown): param is ILogParams {
    if (typeof param !== 'object' || param === null) return false
    return (
      param.hasOwnProperty('id') ||
      param.hasOwnProperty('component') ||
      param.hasOwnProperty('service')
    )
  }
}
