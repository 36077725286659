import { EventCategories, EventPriorities } from '../core'
import { declareEvent } from './declareEvent'

export type FormValidateFailEventPayload = {
  /** название формы */
  name: string
  values: Record<string, any>
  message?: string
  errors?: Record<string, any>
}

/**
 * Ошибка валидации формы
 */
export const FormValidateFailEvent = declareEvent<FormValidateFailEventPayload>(
  {
    subject: 'form',
    action: 'validate',
    result: 'fail',
    category: EventCategories.UX,
    priority: EventPriorities.LOW
  }
)
