import { HmmApi, HmmApiConfig } from "./HmmApi";

export class CustomerOrdersApi extends HmmApi {
  constructor(config: HmmApiConfig) {
    super(config);
    this._names.push('Orders')
    this._route.push('orders')
  }

}
