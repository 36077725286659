import {
  Describe,
  type,
  string,
  nullable,
  number,
  array,
  optional,
  boolean
} from 'superstruct'
import { assertContract } from 'contracts/assertContract'

export type DS_Banner = {
  id: number
  url: string
  slot: string
  enabled: boolean
  description?: string | null
  title?: string | null
  images: {
    id: number
    url: string
    dpr: number
  }[]
}

export const banner_contract: Describe<DS_Banner> = type({
  id: number(),
  url: string(),
  slot: string(),
  enabled: boolean(),
  description: optional(nullable(string())),
  title: optional(nullable(string())),
  images: array(
    type({
      id: number(),
      url: string(),
      dpr: number()
    })
  )
})

export const assertBannerContract = (data: unknown) =>
  assertContract(data, banner_contract)
