import { Either, left, right } from '@sweet-monads/either'
import { assert, Describe, StructError } from 'superstruct'

/**
 * Проверяет данные на соответствие контракту
 * @param data
 * @param contract
 */
export function assertContract<T>(
  data: unknown,
  contract: Describe<T>
): Either<StructError, T> {
  try {
    assert(data, contract)
    return right(data as T)
  } catch (err) {
    return left(err as StructError)
  }
}
