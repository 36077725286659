import { IEvent, EventProcessor, TSubscription } from '@hmm/core'
import { IChannel } from './IChannel'
import { generateSubscriptionKey, matchEvent } from './subscribtion'
import { SubscriptionStore } from './SubscriptionStore'

export class LocalChannel implements IChannel {
  private subscriptions: SubscriptionStore = new SubscriptionStore()

  off(subscription: TSubscription, processor: EventProcessor): void {
    const key = generateSubscriptionKey(subscription)
    if (!this.subscriptions.has(key)) return
    const processors = this.subscriptions.get(key) || []
    const filtered = processors.filter((p) => p !== processor)
    if (filtered.length) {
      this.subscriptions.set(key, filtered)
    } else {
      this.subscriptions.delete(key)
    }
  }

  on(subscription: TSubscription, processor: EventProcessor): void {
    const key = generateSubscriptionKey(subscription)
    if (this.subscriptions.has(key)) {
      const processors = this.subscriptions.get(key) || []
      processors.push(processor)
    } else {
      this.subscriptions.set(key, [processor])
    }
  }

  async sendEvent(event: IEvent): Promise<void> {
    const iterator = this.subscriptions.getKeysIterator()
    for (const key of iterator) {
      if (!matchEvent(event, key)) continue
      const processors = this.subscriptions.get(key) || []
      processors.forEach((processorFn) => processorFn(event))
    }
  }
}
