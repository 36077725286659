import { isBrowser } from '@hmm/utils'

/**
 * Текущее окружение.
 * Клиент или сервер
 */
export type envType = 'client' | 'server'

/**
 * Возвращает текущее окружение
 * @return текущее окружение
 */
export function getEnvironment(): envType {
  return isBrowser() ? 'client' : 'server'
}

export { useEnvironment } from './useEnvironment'
