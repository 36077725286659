import { AbstractApi } from '../AbstractApi'
import { IApiClient } from '../ports/out/IApiClient'
import { ApiResultDTO } from '../ApiResultDTO'
import { IKeyValueStorage } from '../../Storage'
import { ExceptionLoggerPort } from './ports/out/exception-logger.port'
import { TimingCollectorPort } from './ports/out/timing-collector.port'

const refreshToken = Symbol('refreshTokenRequest')

export type HmmApiConfig = {
  baseUrl: string
  client: IApiClient
  tokenStorage: IKeyValueStorage<string>
  exceptionLogger?: ExceptionLoggerPort
  timingCollector?: TimingCollectorPort
}
export type reqData = BodyInit | Record<string, unknown>
export type reqParams = Record<string, unknown> | URLSearchParams
type apiClientMethods = 'create' | 'read' | 'update' | 'patch' | 'delete'
type reqBodyHeaders = [BodyInit, HeadersInit | undefined]

export class HmmApi<RParams extends reqParams = reqParams> extends AbstractApi {
  protected readonly _client: IApiClient
  protected readonly _tokenStorage: IKeyValueStorage<string>
  protected readonly _exceptionLogger?: ExceptionLoggerPort
  protected readonly _timingCollector?: TimingCollectorPort
  /** счетчик неудачных попыток */
  private _effortsCounter = 0
  /** части роута */
  protected _route: Array<string | number> = []

  constructor(config: HmmApiConfig) {
    super(config.baseUrl)
    this._client = config.client
    this._tokenStorage = config.tokenStorage
    this._exceptionLogger = config.exceptionLogger
    this._timingCollector = config.timingCollector

    this._route.push(this._base)
  }

  async get(params?: RParams): Promise<ApiResultDTO>
  async get(
    route: string,
    params?: RParams
  ): Promise<ApiResultDTO>
  async get(
    one?: string | RParams,
    two?: RParams
  ): Promise<ApiResultDTO> {
    let route: string = ''
    let params: RParams | undefined
    if (typeof one === 'object') {
      params = one
    } else if (typeof one === 'string') {
      route = one
      params = two
    }

    return this._query(
      HmmApi.buildQueryDTO(
        'read',
        this._buildRoute(route),
        params,
        null,
        this._getRequestHeaders(),
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }

  async getOne(
    id: string | number,
    params?: RParams
  ): Promise<ApiResultDTO> {
    return this._query(
      HmmApi.buildQueryDTO(
        'read',
        this._buildRoute(id),
        params,
        null,
        this._getRequestHeaders(),
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }

  async search(to: string, params: RParams) {
    return this._query(
      HmmApi.buildQueryDTO(
        'read',
        this._buildRoute(),
        { search: to, ...params },
        null,
        this._getRequestHeaders(),
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }

  async create(data: reqData, params?: RParams): Promise<ApiResultDTO> {
    const [encodedData, headers] = this._encodeBody(data)
    return this._query(
      HmmApi.buildQueryDTO(
        'create',
        this._buildRoute(),
        params || null,
        encodedData,
        this._getRequestHeaders(headers),
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }

  async update(id: string | number, data: reqData, params?: RParams): Promise<ApiResultDTO> {
    const [encodedData, headers] = this._encodeBody(data)
    return this._query(
      HmmApi.buildQueryDTO(
        'patch',
        this._buildRoute(id),
        params || null,
        encodedData,
        headers,
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }

  async delete(
    id: string | number,
    data: reqData | null = null,
    params?: reqParams
  ): Promise<ApiResultDTO> {
    let encodedData = data,
      headers

    if (data) {
      ;[encodedData, headers] = this._encodeBody(data)
    }

    return this._query(
      HmmApi.buildQueryDTO(
        'delete',
        this._buildRoute(id),
        params || null,
        encodedData as BodyInit | null,
        headers,
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }

  protected _encodeBody(body: reqData): [BodyInit, HeadersInit | undefined] {
    let encodedBody: BodyInit
    if (typeof body === 'object' && HmmApi.isFormData(body)) {
      encodedBody = body
    } else if (HmmApi.isRecordObject(body)) {
      return HmmApi.encodeToJSON(body)
    } else {
      encodedBody = body
    }
    return [encodedBody, undefined]
  }

  protected _getRequestHeaders(headers: HeadersInit = {}): HeadersInit {
    const token = this._getToken()
    let auth = {}

    if (token) auth = { Authorization: 'Bearer ' + token }

    return { Accept: 'application/json', ...auth, ...headers }
  }

  protected _buildRoute(
    subroute?: string | number | Array<string | number> | undefined
  ): string {
    if (!subroute) return this._route.join('/')
    return this._route.concat(subroute).join('/')
  }

  protected async _query(dto: QueryDTO): Promise<ApiResultDTO> {
    let request: Promise<Response>
    switch (dto.method) {
      case 'create':
        request = this._client.create(
          dto.url,
          dto.data,
          dto.headers,
          dto.credentials
        )
        break
      case 'read':
        request = this._client.read(
          dto.url,
          dto.params === null ? undefined : dto.params,
          dto.headers,
          dto.credentials
        )
        break
      case 'update':
        request = this._client.update(
          dto.url,
          dto.data,
          dto.headers,
          dto.credentials
        )
        break
      case 'patch':
        request = this._client.patch(
          dto.url,
          dto.data,
          dto.headers,
          dto.credentials
        )
        break
      case 'delete':
        request = this._client.delete(
          dto.url,
          dto.data,
          dto.headers,
          dto.credentials
        )
        break
    }

    return request
      .catch((err) => {
        if (err instanceof Error) this._sendException(err, dto)
        throw err
      })
      .then((res) => {
        this._sendTiming(dto)
        return res
      })
      .then((res) => this._processResponse(res, dto))
  }

  private async _processResponse(
    res: Response,
    queryDTO: QueryDTO
  ): Promise<ApiResultDTO> {
    this._updateToken(res)

    return AbstractApi.processResponse(res)
      .then((apiResult) => {
        this._resetEfforts()
        return apiResult
      })
      .catch((res: ApiResultDTO | Error) => {
        if (res instanceof ApiResultDTO) {
          if (res.raw.status === 401 && this._allowRetry()) {
            this._effortsCounter++
            return this._manualRefreshToken().then(() => this._query(queryDTO))
          }

          return Promise.reject(res)
        }

        throw res
      })
  }

  private _resetEfforts() {
    this._effortsCounter = 0
  }

  /** Можно ли повторить запрос */
  private _allowRetry(): boolean {
    return this._effortsCounter < HmmApi.MAX_EFFORTS
  }

  private _updateToken(res: Response): Response {
    const token = res.headers.get('Authorization')
    if (token) this._setToken(token)

    return res
  }

  private _setToken(token: string) {
    this._tokenStorage.set(HmmApi.TOKEN_KEY, token)
  }

  private _getToken(): string | undefined {
    const token = this._tokenStorage.get(HmmApi.TOKEN_KEY)
    if (typeof token === 'string') return token
    return
  }

  /**
   * Пытается получить новый токен
   * @private
   */
  private async _manualRefreshToken(): Promise<Response> {
    let req = HmmApi[refreshToken]
    if (req) return req

    HmmApi[refreshToken] = req = this._client
      .read(
        this._base + '/auth/token',
        undefined,
        undefined,
        HmmApi.INCLUDE_CREDENTIALS
      )
      .then((res) => this._updateToken(res))
      .finally(() => {
        delete HmmApi[refreshToken]
      })

    return req
  }

  private _sendTiming(qo: QueryDTO) {
    this._timingCollector?.sendTiming(
      `${this.name} :: ${qo.method}`,
      Date.now() - qo.timestamp,
      'API'
    )
  }

  private _sendException(err: Error, qo: QueryDTO) {
    const { method, url, timestamp } = qo
    this._exceptionLogger?.sendException(err, { method, url, timestamp })
  }

  static buildQueryDTO(
    method: apiClientMethods,
    url: string,
    params: reqParams | null = null,
    data: BodyInit | null = null,
    headers: HeadersInit | null = null,
    credentials?: RequestCredentials
  ) {
    return new QueryDTO(method, url, params, data, headers || {}, credentials)
  }

  static isFormData(object: object): object is FormData {
    let result: boolean = false
    try {
      result = object instanceof FormData
    } catch (err) {
      if (
        err instanceof ReferenceError &&
        err.message === 'FormData is not defined'
      ) {
        // Nodejs stub. This code shoud never be reached in browser
        result = object.toString() === '[object FormData]'
      }
    }

    return result
  }

  static isRecordObject(o: any): o is Record<string, unknown> {
    if(typeof o !== 'object') return false
    return Object.keys(o).length > 0
  }

  static encodeToJSON(body: Record<string, unknown>): reqBodyHeaders {
    return [JSON.stringify(body), HmmApi.JSON_HEADERS]
  }

  static INCLUDE_CREDENTIALS: RequestCredentials = 'include'
  /** Количество попыток запроса */
  static MAX_EFFORTS = 3
  /** Ключ api токена в хранилище */
  static TOKEN_KEY = 'token'
  /** Запрос на обновление токена */
  private static [refreshToken]: Promise<Response> | undefined
}

class QueryDTO {
  readonly timestamp: number = Date.now()
  constructor(
    readonly method: apiClientMethods,
    readonly url: string,
    readonly params: reqParams | null,
    readonly data: BodyInit | null,
    readonly headers: HeadersInit,
    readonly credentials?: RequestCredentials
  ) {}
}
