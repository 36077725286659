import { injected } from 'brandi'
import {
  CustomerEntity,
  GetCurrentCustomerQuery,
  IDomainEvent,
  IDomainEventTracker,
  IEvent,
  IEventBus,
  OrderCreatedEvent
} from '@hmm/core'
import { isBrowser } from '@hmm/utils'
import { ILogger, LOGGER_TOKEN } from 'modules/Logger'
import { CONFIG_MANAGER_TOKEN } from 'modules/Config'
import { EVENT_BUS } from 'shared/event-bus'
import { IConfigManager } from 'shared/config'
import {
  Integration,
  ISemanticEvent,
  Spider,
  SpiderContext
} from 'shared/spider'
import {
  ActionAttemptEvent,
  ActionDoneEvent,
  FormValidateFailEvent,
  OrderCreateEvent,
  PageViewEvent,
  UserSubscribeEvent
} from 'shared/spider/events'
import { INTEGRATIONS_NAMES } from './integrations_names'
import {
  createCarrotQuest,
  createDataLayer,
  createGTM,
  createMyTarget,
  createRetailRocket,
  createSendsay,
  createUniversalAnalytics,
  createVK,
  createYandexMetrika
} from './factories'
import { getDefaultCurrency } from './getDefaultCurrency'

const service = '🕷️'

export class SpiderService implements IDomainEventTracker {
  private readonly spider: Spider
  private ctx: SpiderContext
  constructor(
    private readonly _configManager: IConfigManager,
    private readonly logger: ILogger,
    private readonly event_bus: IEventBus
  ) {
    this.ctx = this.initCtx()
    this.spider = new Spider(10, this.ctx)
    this.subscribeEvents()
    this.initIntegrations()
  }

  private initCtx() {
    const ctx: Partial<SpiderContext> = {
      system: {
        currencyCode: getDefaultCurrency(this._configManager)
      }
    }
    return Spider.createContext(ctx)
  }

  private subscribeEvents() {
    this.subscribeSpiderEvents();
    this.event_bus.subscribe(
      { target: 'spider' },
      this._onMessageReceived.bind(this)
    )
    this.event_bus.subscribe(
      { source: 'customer-order' },
      this.trackEvent.bind(this)
    )
    this.event_bus.notify({
      source: 'spider',
      topic: 'get-cart',
      data: null
    })
    this.event_bus.notify({
      source: 'spider',
      topic: 'get-current-customer',
      data: new GetCurrentCustomerQuery()
    })
  }

  private subscribeSpiderEvents(): void {
    const onSpiderEvent = (e: IEvent) => this.trackSpiderEvent(e.data)
    const events = [
      PageViewEvent,
      UserSubscribeEvent,
      FormValidateFailEvent,
      ActionAttemptEvent,
      ActionDoneEvent
    ]
    for (const eventType of events) {
      this.event_bus.subscribe({ topic: eventType.eventName }, onSpiderEvent)
    }
  }

  private _onMessageReceived(e: IEvent) {
    switch (e.topic) {
      case 'cart':
        this.ctx.cart.count = e.data.count
        break
      case 'current-customer':
        const user = e.data as CustomerEntity | null
        if (user === null) return
        this.ctx.user.isAdminScope = user.has_admin_access
        this.ctx.user.id = user.serializeId()
        this.ctx.user.LSCode = user.lscode
        this.ctx.user.email = user.email ? user.email.toString() : undefined
        break
    }
  }

  private initIntegrations() {
    const global = window
    SpiderService.getIntegrationNames(this._configManager).map((name) => {
      SpiderService.createIntegration(
        name,
        this._configManager,
        global,
        this.ctx
      )
        .then((integration) => {
          this.logger.info('Loaded integration', integration.displayName, {
            service
          })
          this.spider.addIntegration(integration)
        })
        .catch((result) => {
          this.logger.warn('Can not load integration', result.reason.message, {
            service
          })
        })
    })
  }

  public async track(events: IDomainEvent[] | IDomainEvent) {
    Array.isArray(events)
      ? events.map(this.trackDomainEvent.bind(this))
      : this.trackDomainEvent(events)
  }

  public trackDomainEvent(event: IDomainEvent) {
    const semantic_event = SpiderService.convertEventToSemanticEvent(event)
    if (!semantic_event) return
    this.spider.trackEvent(semantic_event)
  }

  public async trackEvent(event: IEvent) {
    if (
      event.data &&
      typeof event.data.name === 'string' &&
      typeof event.data.domain === 'string'
    ) {
      this.trackDomainEvent(event.data as IDomainEvent)
    }
  }

  public trackSpiderEvent(event: ISemanticEvent) {
    this.logger.debug(`Track event "${event.name}"`, { service })
    this.spider.trackEvent(event)
  }

  static convertEventToSemanticEvent(e: IDomainEvent): ISemanticEvent | null {
    switch (e.domain) {
      case 'customer-order':
        switch (e.name) {
          case 'created':
            return OrderCreateEvent((e as OrderCreatedEvent).data)
          default:
            return null
        }
      default:
        return null
    }
  }

  /**
   * Получает список активных интеграций
   * @param configManager
   */
  static getIntegrationNames(
    configManager: IConfigManager
  ): INTEGRATIONS_NAMES[] {
    const names = [INTEGRATIONS_NAMES.DATA_LAYER]
    // GoogleTagManager
    if (configManager.get('GTM_ID')) {
      names.push(INTEGRATIONS_NAMES.GTM)
    }
    // Universal Analytics
    if (configManager.get('GOOGLE_ANALYTICS_ID')) {
      names.push(INTEGRATIONS_NAMES.UNIVERSAL_ANALYTICS)
    }
    // Yandex Metrika
    if (configManager.get('YANDEX_METRIKA_ID')) {
      names.push(INTEGRATIONS_NAMES.YANDEX_METRIKA)
    }
    // Retail Rocket
    if (configManager.get('RETAIL_ROCKET_ID')) {
      names.push(INTEGRATIONS_NAMES.RETAIL_ROCKET)
    }
    // CarrotQuest
    if (configManager.get('CARROTQUEST_ID')) {
      names.push(INTEGRATIONS_NAMES.CARROTQUEST)
    }
    // MyTarget
    if (configManager.get('MYTARGET_TOPMAIL_ID')) {
      names.push(INTEGRATIONS_NAMES.MYTARGET)
    }

    // VK
    if (configManager.get('VK_PIXEL_ID')) {
      names.push(INTEGRATIONS_NAMES.VK)
    }

    // Sendsay
    if (
      configManager.get('SENDSAY_ID') ||
      (isBrowser() && typeof (window as any).sndsy !== 'undefined')
    ) {
      names.push(INTEGRATIONS_NAMES.SENDSAY)
    }

    return names
  }

  static async createIntegration(
    name: INTEGRATIONS_NAMES,
    config: IConfigManager,
    global: any,
    ctx: SpiderContext
  ): Promise<Integration> {
    switch (name) {
      case INTEGRATIONS_NAMES.DATA_LAYER:
        return createDataLayer(config, global, ctx)
      case INTEGRATIONS_NAMES.GTM:
        return createGTM(config, global, ctx)
      case INTEGRATIONS_NAMES.YANDEX_METRIKA:
        return createYandexMetrika(config, global, ctx)
      case INTEGRATIONS_NAMES.CARROTQUEST:
        return createCarrotQuest(config, global, ctx)
      case INTEGRATIONS_NAMES.MYTARGET:
        return createMyTarget(config, global, ctx)
      case INTEGRATIONS_NAMES.VK:
        return createVK(config, global, ctx)
      case INTEGRATIONS_NAMES.SENDSAY:
        return createSendsay(config, global, ctx)
      case INTEGRATIONS_NAMES.UNIVERSAL_ANALYTICS:
        return createUniversalAnalytics(config, global, ctx)
      case INTEGRATIONS_NAMES.RETAIL_ROCKET:
        return createRetailRocket(config, global, ctx)
      default:
        throw new Error(`Unknown integration "${name}"`)
    }
  }
}

injected(SpiderService, CONFIG_MANAGER_TOKEN, LOGGER_TOKEN, EVENT_BUS)
