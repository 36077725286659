import {
  ShippingAddressEntity,
  ID,
  Person,
  Email,
  MobilePhone,
  PickupPoint,
  Position,
  Country
} from '@hmm/core'
import { DS_CustomerAddress } from './contract'
import { HomeAddressFactory } from 'factories/HomeAddressFactory'
import { PositionDTO } from 'contracts/Position'
import { PositionFactory } from 'factories/PositionFactory'

export class CustomerAddressFactory {
  static create(dto: DS_CustomerAddress): ShippingAddressEntity {
    const position_dto = PositionDTO.create({
      ...dto,
      postcode: dto.post_code || null,
      province: dto.province || undefined
    })
    const target = dto.pvz_code
      ? PickupPoint.create({
          position: PositionFactory.create(position_dto),
          code: dto.pvz_code,
          service: 'unknown'
        })
      : HomeAddressFactory.create(position_dto)

    return ShippingAddressEntity.create({
      id: new ID(dto.id),
      target,
      recipient: Person.create(dto),
      email: dto.email ? new Email(dto.email) : null,
      phone: dto.phone ? new MobilePhone(dto.phone) : null
    })
  }
}
