// noinspection JSUnusedGlobalSymbols

import './global.css'
import Head from 'next/head'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '../emotionCache'
import { bootstrap } from 'application/bootstrap'
import { ContainerProvider } from 'brandi-react'
import { API_CLIENT_TOKEN } from 'modules/Api'
import {
  HmmApiFactory,
  HMM_API_CONFIG_TOKEN,
  API_TOKEN_STORAGE_TOKEN,
  HMM_API_FACTORY_TOKEN,
  HMM_API_URL_TOKEN
} from 'modules/Api/HmmApi'
import { SPIDER } from 'modules/Spider'
import { DS_PageMeta } from 'contracts/pages'
import { AppProps } from 'next/app'
import { NextRouter } from 'next/router'
import { getProp, isBrowser } from '@hmm/utils'
import { ROUTER } from 'modules/Router'
import { InitialDataContext } from 'shared/initialData'
import { AppService } from 'application/AppService'
import { CSRFTokenContextProvider } from 'entities/CSRFToken'

const clientSideEmotionCache = createEmotionCache()
const container = bootstrap()

export default function MyApp(
  props: AppProps<{
    publicEnv?: Record<string, string | number | boolean>
    meta?: DS_PageMeta
  }> & { emotionCache: any }
) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    router
  } = props
  const pageMeta = useMemo(
    () => pageProps.meta || { title: '' },
    [pageProps.meta]
  )

  useMemo(() => {
    const config = AppService.initConfigManager(
      container,
      pageProps.publicEnv || {}
    )
    container
      .bind(ROUTER)
      .toInstance(() => router)
      .inSingletonScope()

    container.bind(HMM_API_URL_TOKEN).toConstant(String(config.get('API_URL')))

    container
      .bind(HMM_API_CONFIG_TOKEN)
      .toInstance(() => ({
        baseUrl: container.get(HMM_API_URL_TOKEN),
        client: container.get(API_CLIENT_TOKEN),
        tokenStorage: container.get(API_TOKEN_STORAGE_TOKEN)
        //exceptionLogger?: ExceptionLoggerPort
        //timingCollector?: TimingCollectorPort
      }))
      .inSingletonScope()

    container
      .bind(HMM_API_FACTORY_TOKEN)
      .toInstance(HmmApiFactory)
      .inContainerScope()

    if (isBrowser()) {
      // @ts-ignore
      window['configManager'] = config
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const appService = useMemo(() => {
    const app = new AppService(container, pageProps)
    // @ts-ignore
    isBrowser() && (window.appService = app)
    return app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => {
    // start Spider only in browser
    if (isBrowser()) {
      // @ts-ignore
      window['spider'] = container.get(SPIDER)
    }
  }, [])

  const [url, setUrl] = useState(router.pathname)

  const handler = useCallback(
    (url: string, meta: DS_PageMeta, router: NextRouter) => {
      appService.sendPageView({
        url,
        title: meta.title,
        path: router.pathname,
        type: meta.bundle || 'unknown'
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    handler(url, pageMeta, router)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [url /*, router, handler, pageMeta*/])

  useEffect(() => {
    const handler = (url: string, params: { shallow: boolean }) => {
      if (params.shallow) return
      setUrl(url)
    }
    router.events.on('routeChangeComplete', handler)
    return () => router.events.off('routeChangeComplete', handler)
  }, [router])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>{pageMeta.title}</title>
      </Head>
      <InitialDataContext.Provider value={pageProps}>
        <CSRFTokenContextProvider value={getProp(pageProps, 'app.csrf')}>
          <ContainerProvider container={container}>
            <Component {...pageProps} />
          </ContainerProvider>
        </CSRFTokenContextProvider>
      </InitialDataContext.Provider>
    </CacheProvider>
  )
}
