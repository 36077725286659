import { DS_Promotion } from 'contracts/Promotion'
import {
  DateTime,
  ID,
  PromotionEntity,
  PromotionLayer,
  OrderPercentageAction,
  OrderItemTotalPromotionRule, Money
} from '@hmm/core'

export class PromotionFactory {
  static create(data: DS_Promotion): PromotionEntity {
    return PromotionEntity.create({
      ...data,
      rules : data.amount?
      [new OrderItemTotalPromotionRule({
        min : new Money(data.amount.min),
        max : data.amount.max? new Money(data.amount.max): undefined
      })]: [],
      // @ts-ignore
      actions: data.actions? [new OrderPercentageAction(data.actions.order_pecentage_discount.percentage)]: [],
      starts_at: DateTime.fromUnix(data.starts_at || data.created_at),
      ends_at: data.ends_at ? DateTime.fromUnix(data.ends_at) : undefined,
      layer: data.layer as PromotionLayer,
      description: data.description || '',
      id: ID.persistent(data.id),
      banners: data.banners.map((banner) => ID.persistent(banner.id))
    })
  }
}
