// Оригинал: can-param

interface IParamObject {
  [key: string]: any
}

export const param = (object: IParamObject) => {
  const pairs: Array<string> = []

  const add = (key: string, value: string) => {
    pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
  }

  for (var name in object) {
    buildParam(name, object[name], add)
  }

  return pairs.join('&').replace(/%20/g, '+')
}

function buildParam(prefix: string, obj: IParamObject, add: Function) {
  if (Array.isArray(obj)) {
    for (var i = 0, l = obj.length; i < l; ++i) {
      var inner = obj[i]
      var shouldIncludeIndex = typeof inner === 'object'
      var arrayIndex = shouldIncludeIndex ? '[' + i + ']' : '[]'
      buildParam(prefix + arrayIndex, inner, add)
    }
  } else if (obj && typeof obj === 'object') {
    for (var name in obj) {
      buildParam(prefix + '[' + name + ']', obj[name], add)
    }
  } else {
    add(prefix, obj)
  }
}
