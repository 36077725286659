import { DS_CurrentUser } from './CurrentUser.contract'
import { CustomerEntity, ID, Person, Email, MobilePhone } from '@hmm/core'

export class CurrentUserFactory {
  static create(data: DS_CurrentUser): CustomerEntity {
    return CustomerEntity.create({
      id: new ID(data.id),
      person: Person.create({
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        middle_name: data.middle_name || ''
      }),
      role: CustomerEntity.getRoleFromString(data.role),
      //lscode: data.lscode,
      email: data.email ? new Email(data.email) : null,
      mobile: data.phone ? new MobilePhone(data.phone) : null
    })
  }
}
