import { HmmApi, HmmApiConfig } from './HmmApi'
import { ImageApiDecorator, IUploadableImage } from './ImageApiDecorator'

export class HmmProductsApi extends HmmApi {
  constructor(config: HmmApiConfig) {
    super(config)
    this._names.push('Products')
    this._route.push('products')
  }

  print(id: string | number, data: Record<string, unknown>) {
    const [body, headers] = this._encodeBody(data)
    return this._query(
      HmmApi.buildQueryDTO(
        'create',
        this._buildRoute([id, 'print']),
        null,
        body,
        this._getRequestHeaders(headers),
        HmmApi.INCLUDE_CREDENTIALS
      )
    )
  }
}

export class HmmProductPackingsApi extends HmmProductsApi {
  private _id: number | string
  constructor(productId: number | string, config: HmmApiConfig) {
    super(config)
    this._id = productId
    this._names.push('Packings')
    this._route.push(this._id)
    this._route.push('packings')
  }

  get id() {
    return this._id
  }

  set id(id) {
    this._route[this._route.length - 2] = id
    this._id = id
  }
}

export class HmmProductImagesApi extends HmmProductsApi {
  constructor(id: number | string, config: HmmApiConfig) {
    super(config)
    this._names.push('Images')
    this._route.push(id)
    this._route.push('images')
  }

  create(data: IUploadableImage) {
    return super.create(ImageApiDecorator.create(data))
  }

  static file2base64 = ImageApiDecorator.file2base64
}

export class HmmProductsFilterApi extends HmmApi {
  _init() {
    this._names.push('Filter')
    this._route.push('filter')
  }
}
