import { Container } from 'brandi'
import { CustomerService, IEventBus, PromotionsService } from '@hmm/core'
import { isBrowser } from '@hmm/utils'
import { getEnvironment, envType } from 'shared/environment'
import { MapCache } from 'shared/storage'
import { EVENT_BUS, EventBus } from 'shared/event-bus'
import { IConfigManager } from 'shared/config'
import { PageViewEvent } from 'shared/spider/events'
import { ILogger, LOGGER_TOKEN } from 'modules/Logger'
import {
  ConfigManager,
  IConfigStorage,
  ConfigBrowserStorage,
  ConfigStorageVariable,
  CONFIG_MANAGER_TOKEN
} from 'modules/Config'
import { HmmPromotionsApi } from 'modules/Api/HmmApi'
import {
  init as initPromotions,
  UserPromotionsModule,
  PROMOTIONS_SERVICE
} from 'modules/UserPromotions'
import {
  init as initCurrentUser,
  CurrentUserModule,
  CUSTOMER_SERVICE
} from 'modules/CurrentUser'

const service = 'AppService'

type InitialData = {
  [key: string]: unknown
  publicEnv?: Record<string, string | number | boolean>
}

const DEBUG_EVENT_BUS_KEY = 'debug:event_bus'

export class AppService {
  private readonly _logger: ILogger
  private readonly _environment: envType = getEnvironment()
  private readonly _event_bus: IEventBus
  private readonly _config: IConfigManager
  private readonly _customerService: CustomerService
  constructor(
    private readonly _ioc: Container,
    private readonly _initialData: InitialData
  ) {
    this._logger = this._ioc.get(LOGGER_TOKEN)
    this._config = AppService.initConfigManager(
      this._ioc,
      this._initialData.publicEnv || {}
    )
    this._event_bus = AppService.initEventBus(this._ioc, this._config)
    this.initPromotionsService()
    this._customerService = this.initCurrentUserService()
    this.subscribeEvents()
  }

  sendPageView(page: {
    url: string
    title: string
    type: string
    path: string
  }) {
    this._event_bus.notify({
      source: service,
      topic: PageViewEvent.eventName,
      data: PageViewEvent({ page })
    })
  }

  static initConfigManager(ioc: Container, publicEnv: Record<string, unknown>) {
    try {
      return ioc.get(CONFIG_MANAGER_TOKEN)
    } catch (_) {}
    const storages: IConfigStorage[] = []
    if (isBrowser()) {
      storages.push(
        new ConfigBrowserStorage(sessionStorage),
        new ConfigBrowserStorage(localStorage)
      )
    }
    storages.push(new ConfigStorageVariable(publicEnv))

    ioc
      .bind(CONFIG_MANAGER_TOKEN)
      .toInstance(() => new ConfigManager(storages))
      .inSingletonScope()

    return ioc.get(CONFIG_MANAGER_TOKEN)
  }

  static initEventBus(container: Container, config: IConfigManager) {
    let event_bus: IEventBus
    try {
      event_bus = container.get(EVENT_BUS)
    } catch (_) {
      container.bind(EVENT_BUS).toInstance(EventBus).inSingletonScope()
      event_bus = container.get(EVENT_BUS)
    }

    if (config.get(DEBUG_EVENT_BUS_KEY)) {
      import('../../shared/event-bus/DebugChannel.js')
        .then((module) => {
          const { DebugChannel } = module
          const logger = container.get(LOGGER_TOKEN)
          const debug_channel = new DebugChannel(logger)
          // @ts-ignore
          typeof event_bus.connect === 'function' &&
            // @ts-ignore
            event_bus.connect(debug_channel)
        })
        .catch((err) => {
          const console = container.get(LOGGER_TOKEN)
          console.warn('Error loading EventBus debug channel', err, {
            service
          })
        })
    }

    return event_bus
  }

  private subscribeEvents(): void {
    this._event_bus.subscribe({ topic: 'get-current-customer' }, async (e) => {
      const customer = this._customerService.getCurrentCustomer()
      this._event_bus.notify({
        target: e.source,
        source: service,
        topic: 'current-customer',
        data: customer
      })
    })
  }

  private initPromotionsService(): PromotionsService {
    this._ioc.use(PROMOTIONS_SERVICE).from(UserPromotionsModule)
    initPromotions(
      {
        banners: { data: this._initialData.banners, cache: new MapCache() },
        promotions: {
          data: this._initialData.promotions,
          cache: new MapCache(),
          provider: HmmPromotionsApi
        }
      },
      this._logger,
      this._environment
    )
    return this._ioc.get(PROMOTIONS_SERVICE)
  }

  private initCurrentUserService(): CustomerService {
    this._ioc.use(CUSTOMER_SERVICE).from(CurrentUserModule)
    initCurrentUser(
      this._initialData.user,
      new MapCache(),
      new MapCache(),
      this._logger,
      this._environment
    )
    return this._ioc.get(CUSTOMER_SERVICE)
  }
}
