import { token, injected, DependencyModule } from 'brandi'
import { PromotionsRepository, PromotionsCache } from './PromotionsRepository'
import { BannersCache, BannersRepository } from './BannersRepository'
import { IBannerRepository, PromotionsService } from '@hmm/core'

import { DOMAIN_EVENT_TRACKER } from '../../services/TOKENS'
import { LOGGER_TOKEN } from 'modules/Logger'
import { IReadableAPI } from 'shared/repository'

export const UserPromotionsModule = new DependencyModule()
export const PROMOTIONS_SERVICE = token<PromotionsService>('PromotionsService')
export const PROMOTIONS_REPOSITORY = token<PromotionsRepository>(
  'PromotionsRepository'
)
export const PROMOTIONS_PROVIDER = token<IReadableAPI>('PromotionsApi')
export const PROMOTIONS_CACHE = token<PromotionsCache>('PromotionsCache')
export const BANNERS_REPOSITORY = token<IBannerRepository>('IBannerRepository')
export const BANNERS_CACHE = token<BannersCache>('BannersCache')

injected(
  PromotionsService,
  PROMOTIONS_REPOSITORY,
  BANNERS_REPOSITORY,
  DOMAIN_EVENT_TRACKER
)

injected(
  PromotionsRepository,
  PROMOTIONS_PROVIDER,
  PROMOTIONS_CACHE,
  LOGGER_TOKEN
)
injected(BannersRepository, BANNERS_CACHE)

UserPromotionsModule.bind(PROMOTIONS_SERVICE)
  .toInstance(PromotionsService)
  .inSingletonScope()

UserPromotionsModule.bind(PROMOTIONS_REPOSITORY)
  .toInstance(PromotionsRepository)
  .inSingletonScope()

UserPromotionsModule.bind(BANNERS_REPOSITORY)
  .toInstance(BannersRepository)
  .inSingletonScope()

