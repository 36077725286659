import { HmmApi, HmmApiConfig } from "./HmmApi";

export class HmmPromotionsApi extends HmmApi {
  constructor(config: HmmApiConfig) {
    super(config);
    this._names.push('Promotions')
    this._route.push('promotion', 'promotions')
  }

}
