import { IApiClient } from '../ports/out/IApiClient'
import { IKeyValueStorage } from '../../Storage'
import {
  HmmProductPackingsApi,
  HmmProductsApi,
  HmmProductsFilterApi
} from './HmmProductsApi'
import { ExceptionLoggerPort } from './ports/out/exception-logger.port'
import { TimingCollectorPort } from './ports/out/timing-collector.port'
import { HmmApiConfig } from './HmmApi'
import { CustomerCartApi } from './CustomerCartApi'
import { CustomerOrdersApi } from './CustomerOrdersApi'
import { ShippingVariantsApi } from './ShippingVariantsApi'

export class HmmApiFactory {
  private _config: HmmApiConfig
  constructor(
    readonly baseUrl: string,
    client: IApiClient,
    tokenStorage: IKeyValueStorage<string>,
    exceptionLogger?: ExceptionLoggerPort,
    timingCollector?: TimingCollectorPort
  ) {
    this._config = {
      baseUrl,
      client,
      tokenStorage,
      exceptionLogger,
      timingCollector
    }
  }

  getCustomerCartApi() {
    return new CustomerCartApi(this._config)
  }

  getCustomerOrdersApi() {
    return new CustomerOrdersApi(this._config)
  }

  getShippingVariantsApi() {
    return new ShippingVariantsApi(this._config)
  }

  getProductsApi() {
    return new HmmProductsApi(this._config)
  }

  getProductsFilterApi() {
    return new HmmProductsFilterApi(this._config)
  }

  getProductPackingsApi(productId: number | string) {
    return new HmmProductPackingsApi(productId, this._config)
  }
}
