import { string, create } from 'superstruct'
import { nullableDefaulted } from 'shared/validation'
import { IConfigManager } from 'shared/config'
import { Integration, SpiderContext } from 'shared/spider'
import { getDefaultCurrency } from '../getDefaultCurrency'
import { getConfigValueMaybeAdmin } from './getConfigValueMaybeAdmin'
import { createDataLayer as createProxy } from './dataLayer'

export async function createDataLayer(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
) {
  const defaultCurrency = getDefaultCurrency(config)
  const { DataLayer } = await import(
    '../../../shared/spider/integrations/google/data-layer.js'
  )
  createProxy(global);

  return new DataLayer(
    { defaultCurrency, useTranslit: true },
    global,
    ctx
  )
}

export async function createGTM(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const containerId = getConfigValueMaybeAdmin(config, ctx, 'GTM_ID'),
    defaultCurrency = getDefaultCurrency(config)

  const { GoogleTagManager } = await import(
    '../../../shared/spider/integrations/google/google-tag-manager.js'
  )

  return new GoogleTagManager({ containerId, defaultCurrency }, global, ctx)
}

export async function createYandexMetrika(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const counterId = getConfigValueMaybeAdmin(config, ctx, 'YANDEX_METRIKA_ID'),
    purchaseGoalId = create(
      config.get('YANDEX_METRIKA_PURCHASE_GOAL_ID'),
      nullableDefaulted(string(), '')
    ),
    disable_webvisor = create(
      config.get('ym:disable-webvisor'),
      nullableDefaulted(string(), '')
    )

  const { YandexMetrika } = await import(
    '../../../shared/spider/integrations/yandex-metrika.js'
  )
  const {
    ProductAddCartEvent,
    ProductViewEvent,
    UserSubscribeEvent,
    OrderCreateEvent
  } = await import('../../../shared/spider/events/index.js')

  return new YandexMetrika(
    {
      counterId,
      purchaseGoalId,
      dataLayerName: 'yandexDL',
      counterParams: {
        webvisor: !disable_webvisor
      },
      sendUserId: true,
      goals: {
        [OrderCreateEvent.eventName]: 'COMPLETED_TRANSACTION',
        [ProductViewEvent.eventName]: 'VIEWED_PRODUCT_DETAIL',
        [ProductAddCartEvent.eventName]: 'ADDED_PRODUCT',
        [UserSubscribeEvent.eventName]: 'SUBSCRIBED'
      }
    },
    global,
    ctx
  )
}

export async function createCarrotQuest(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const counterId = getConfigValueMaybeAdmin(config, ctx, 'CARROTQUEST_ID')
  const { CarrotQuest } = await import(
    '../../../shared/spider/integrations/CarrotQuest.js'
  )
  return new CarrotQuest({ counterId }, global, ctx)
}

export async function createMyTarget(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const counterId = getConfigValueMaybeAdmin(config, ctx, 'MYTARGET_TOPMAIL_ID')
  const { MyTarget } = await import(
    '../../../shared/spider/integrations/mytarget.js'
  )
  const {
    ProductAddCartEvent,
    ProductRemoveCartEvent,
    ProductAddWishlistEvent,
    ProductRemoveWishlistEvent,
    UserSubscribeEvent,
    OrderCreateEvent
  } = await import('../../../shared/spider/events/index.js')

  return new MyTarget(
    {
      counterId,
      goals: {
        [ProductAddCartEvent.eventName]: 'ADDED_PRODUCT',
        [ProductRemoveCartEvent.eventName]: 'REMOVED_PRODUCT',
        [ProductAddWishlistEvent.eventName]: 'ADDED_PRODUCT_TO_WISHLIST',
        [ProductRemoveWishlistEvent.eventName]: 'REMOVED_PRODUCT_FROM_WISHLIST',
        [UserSubscribeEvent.eventName]: 'SUBSCRIBED',
        [OrderCreateEvent.eventName]: 'COMPLETED_TRANSACTION'
      }
    },
    global,
    ctx
  )
}

export async function createVK(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const pixelId = create(config.get('VK_PIXEL_ID'), string()),
    audienceId = create(
      config.get('VK_AUDIENCE_ID'),
      nullableDefaulted(string(), '')
    ),
    priceListId = create(
      config.get('VK_PRICE_LIST_ID'),
      nullableDefaulted(string(), '')
    )

  const { Vk } = await import('../../../shared/spider/integrations/vk.js')
  return new Vk({ pixelId, audienceId, priceListId }, global)
}

export async function createSendsay(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const fid = create(config.get('SENDSAY_ID'), string()),
    debug = Boolean(config.get('debug:sendsay'))

  const { Sendsay } = await import(
    '../../../shared/spider/integrations/Sendsay.js'
  )

  return new Sendsay({ fid, debug }, global, ctx)
}

export async function createUniversalAnalytics(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const trackingId = getConfigValueMaybeAdmin(
      config,
      ctx,
      'GOOGLE_ANALYTICS_ID'
    ),
    defaultCurrency = getDefaultCurrency(config),
    debug = Boolean(config.get('debug:universal-analytics'))

  const { UniversalAnalytics } = await import(
    '../../../shared/spider/integrations/google/universal-analytics.js'
  )

  return new UniversalAnalytics(
    {
      debug,
      trackingId,
      defaultCurrency,
      sendUserId: true,
      siteSpeedSampleRate: 100,
      customValues: {
        metric1: ['event', 'user.total_spent'],
        dimension4: ['event', 'user.yandexClientId'],
        dimension7: ['event', 'user.googleClientId']
      }
    },
    global,
    ctx
  )
}

export async function createRetailRocket(
  config: IConfigManager,
  global: any,
  ctx: SpiderContext
): Promise<Integration> {
  const partnerId = create(config.get('RETAIL_ROCKET_ID'), string())
  const { RetailRocket } = await import(
    '../../../shared/spider/integrations/RetailRocket.js'
  )

  return new RetailRocket(
    {
      partnerId,
      listMethods: {
        Popular: 'Personalized Popular',
        Latest: 'Personalized Latest',
        Personal: 'Personal',
        Related: 'Related',
        Alternative: 'Alternative',
        'Personal In Profile': 'Personal',
        'Popular In Category': 'Popular',
        'Related For Cart': 'Related',
        'Sale By Popular': 'Sale By Popular',
        'Sale By Latest': 'Sale By Latest',
        'Search Recomms': 'Search',
        'Empty Category Recomms': 'Search'
      }
    },
    global,
    ctx
  )
}
