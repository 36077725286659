import { Country, Position, GeoPoint } from '@hmm/core'
import { PositionDTO } from 'contracts/Position'

export class PositionFactory {
  static create(dto: PositionDTO) {
    const geo_point =
      dto.geopoint &&
      new GeoPoint(dto.geopoint.latitude, dto.geopoint.longitude)

    return Position.create({
      ...dto,
      postcode: dto.postcode || undefined,
      country: new Country(dto.country_code, dto.country_title),
      geo_point
    })
  }
}
