import { left, merge } from '@sweet-monads/either'
import { BannerEntity, Criteria, IBannerRepository } from '@hmm/core'
import { IKeyValueStorage } from 'modules/Storage'
import { assertBannerContract, DS_Banner } from 'contracts/Banner'
import { BannerFactory } from 'factories/BannerFactory'

const ALL_KEY = 'banners'
export type BannersCache = IKeyValueStorage<DS_Banner[], 'banners'>
type BannerCriteria = Criteria & { slot?: string }

export class BannersRepository implements IBannerRepository {
  constructor(
    //private readonly _provider: IReadableAPI,
    private readonly _cache: BannersCache
  ) //private readonly _logger: ILogger
  {}

  async getList(params?: BannerCriteria): Promise<BannerEntity[]> {
    const [banners] = this.getListSync(params)
    return banners
  }

  getListSync(params?: BannerCriteria): [BannerEntity[], Error | undefined] {
    const data = BannersRepository._filterBannersData(
      this._cache.get(ALL_KEY) || [],
      params
    )
    return [BannersRepository.createList(data), undefined]
  }

  async get(params?: BannerCriteria): Promise<BannerEntity | null> {
    const [banner] = this.getSync(params)
    return banner
  }

  getSync(params?: BannerCriteria): [BannerEntity | null, Error | undefined] {
    const ds_banner = BannersRepository._filterBannersData(
      this._cache.get(ALL_KEY) || [],
      params
    ).pop()
    if (!ds_banner) return [null, undefined]
    return [BannersRepository.createEntity(ds_banner), undefined]
  }

  private static _filterBannersData(
    data: DS_Banner[],
    params?: BannerCriteria
  ): DS_Banner[] {
    const filters: Array<(d: DS_Banner) => boolean> = []
    if (params?.id) filters.push((data: DS_Banner) => data.id == params.id)
    if (params?.slot)
      filters.push((data: DS_Banner) => data.slot === params.slot)

    return filters.reduce((result, filter) => {
      return filter ? result.filter(filter) : result
    }, data)
  }

  static createEntity(data: DS_Banner) {
    return BannerFactory.create(data)
  }

  static createList(data: DS_Banner[]) {
    return data.map(BannersRepository.createEntity)
  }

  static assertList(data: unknown) {
    if (!Array.isArray(data)) return left(new Error('Data is not array'))
    return merge(data.map(assertBannerContract))
  }

  static fillCache(data: unknown, cache: BannersCache): BannersCache {
    return BannersRepository.assertList(data)
      .mapRight((banners) => {
        cache.set(ALL_KEY, banners)
        return cache
      })
      .mapLeft((_err) => {
        return cache
      }).value
  }
}
