import { Either, left, merge, right } from '@sweet-monads/either'
import {
  CustomerEntity,
  ID,
  IShippingAddressRepository,
  ShippingAddressCriteria,
  ShippingAddressGetCriteria,
  ShippingAddressEntity
} from '@hmm/core'
import { IApiResultPort } from 'shared/repository'
import { AddressesAPIFactory } from './ports/out'
import { CustomerAddressFactory } from './CustomerAddressFactory'
import { assertCustomerAddressContract, DS_CustomerAddress } from './contract'
import { CustomerAddressDTO } from 'entities/CustomerAddress/CustomerAddressDTO'

export class CustomerAddressesRepository implements IShippingAddressRepository {
  constructor(private readonly _apiFactory: AddressesAPIFactory) {}

  async get({
    customer_id,
    id,
    ...params
  }: ShippingAddressGetCriteria): Promise<ShippingAddressEntity | null> {
    const api = this._getApi(customer_id)
    const response = await api.getOne(id.valueOf(), params)

    return CustomerAddressesRepository.getApiResponseData(response)
      .chain(CustomerAddressesRepository.assertObject)
      .chain(CustomerAddressesRepository.createEntity)
      .mapLeft((err) => null).value
  }

  async getList({
    customer_id,
    ...params
  }: ShippingAddressCriteria): Promise<ShippingAddressEntity[] | null> {
    const api = this._getApi(customer_id)
    const response = await api.get(params)

    return CustomerAddressesRepository.getApiResponseData(response)
      .chain(CustomerAddressesRepository.createList)
      .mapLeft((_err) => {
        return null
      }).value
  }

  async create(
    entity: ShippingAddressEntity,
    customer: CustomerEntity
  ): Promise<ShippingAddressEntity> {
    const api = this._getApi(customer.id)
    const dto = CustomerAddressDTO.createFromModel(entity)
    const response = await api.create(dto)
    return CustomerAddressesRepository.getApiResponseData(response)
      .chain(CustomerAddressesRepository.assertObject)
      .mapRight((created) => {
        return Object.assign({}, dto, created)
      })
      .chain((value) => CustomerAddressesRepository.createEntity(value))
      .mapLeft((err) => {
        throw err
      }).value
  }

  async update(
    entity: ShippingAddressEntity,
    customer: CustomerEntity
  ): Promise<ShippingAddressEntity> {
    const api = this._getApi(customer.id)
    const dto = CustomerAddressDTO.createFromModel(entity)
    const response = await api.update(entity.serializeId(), dto)

    return CustomerAddressesRepository.getApiResponseData(response)
      .chain(CustomerAddressesRepository.assertObject)
      .chain(CustomerAddressesRepository.createEntity)
      .mapLeft((err) => {
        throw err
      }).value
  }
  async delete(
    entity: ShippingAddressEntity,
    customer: CustomerEntity
  ): Promise<boolean> {
    const api = this._getApi(customer.id)
    return api
      .delete(entity.id.valueOf())
      .then(() => true)
      .catch(() => false)
  }

  getSync(): [ShippingAddressEntity | null, Error | undefined] {
    throw new Error('Method not implemented.')
  }
  getListSync(): [ShippingAddressEntity[] | null, Error | undefined] {
    throw new Error('Method not implemented.')
  }

  private _getApi(id: ID) {
    return this._apiFactory(id.valueOf())
  }

  static getApiResponseData(apiResponse: IApiResultPort) {
    return right(apiResponse.data)
  }

  static assertObject(data: unknown) {
    return assertCustomerAddressContract(data)
  }

  static createEntity(
    data: DS_CustomerAddress
  ): Either<Error, ShippingAddressEntity> {
    try {
      const entity = CustomerAddressFactory.create(data)
      return right(entity)
    } catch (err) {
      return left(err as Error)
    }
  }

  static createList(data: unknown) {
    if (!Array.isArray(data)) {
      return left(new Error('Expect data to be an array'))
    }

    return merge(
      data.map((v) =>
        assertCustomerAddressContract(v).mapRight(CustomerAddressFactory.create)
      )
    )
  }
}
