import { ShippingAddressEntity, PickupPoint } from '@hmm/core'
import { DS_CustomerAddress } from './contract'

export class CustomerAddressDTO implements DS_CustomerAddress {
  constructor(
    readonly id: number | undefined,
    readonly country_code: string,
    readonly country_title: string,
    readonly last_name: string,
    readonly first_name: string,
    readonly middle_name: string | null,
    readonly email: string | null,
    readonly phone: string | null,
    readonly post_code: string | null,
    readonly pvz_code: string | null,
    readonly pvz_service: string | null,
    readonly province: string|null,
    readonly locality: string,
    readonly street_address: string,
    readonly customer_id?: number,
    readonly created_at?: number,
    readonly updated_at?: number
  ) {}

  static createFromModel(address: ShippingAddressEntity) {
    const id = address.id.valueOf();
    const position = address.position
    return new CustomerAddressDTO(
      typeof id === 'number' ? id : undefined,
      position?.country.code || 'RU',
      position?.country.title || 'Россия',
      address.recipient.last_name,
      address.recipient.first_name,
      address.recipient.middle_name,
      address.email && address.email.toString() || null,
      address.phone && address.phone.toString() || null,
      position?.postcode || null,
      address.isPickupPoint()? (address.target as PickupPoint).code : null,
      address.isPickupPoint()? (address.target as PickupPoint).service: null,
      position?.province || null,
      position?.locality || '',
      position?.street_address || ''
    )
  }
}
