import { LOG_PRIORITIES } from "./log-priorities";

export class LogMessageDTO {
  constructor(
    readonly message: unknown,
    readonly priority: LOG_PRIORITIES,
    readonly block?: string | null,
    readonly id?: string | number | null
  ) {}
}
