import { JSONValue } from 'shared/types'
import { IObjectStorage } from 'modules/Storage'

export class VariableStorage<T extends JSONValue> implements IObjectStorage<T> {
  private _store: Record<string, T | undefined> = {}
  del(key: string): void {
    delete this._store[key]
  }

  get(key: string): T | undefined {
    return this._store[key]
  }

  has(key: string): boolean {
    return this._store[key] !== undefined
  }

  set(key: string, value: T): boolean {
    this._store[key] = value
    return true
  }
}
