export function createDataLayerProxy<T extends any = Record<string, unknown>>(
  srcArr: Array<T>,
  destArr: Array<T>,
  transform: (e: T, destArr: T[]) => T = (e) => e
) {
  const orig_push = srcArr.push
  srcArr.push = function(event) {
    destArr.push(transform(event, destArr))
    return orig_push.call(srcArr, event)
  }
  return srcArr;
  /*
  return new Proxy(srcArr, {
    get(target: Array<T>, p: string | symbol, receiver: any): any {
      // @ts-ignore
      const originalMethod = target[p]
      switch (p) {
        case 'push':
          return function (event: T) {
            destArr.push.call(destArr, transform(event, destArr))
            return originalMethod.call(receiver, event)
          }
        default:
          return originalMethod
      }
    }
  })
   */
}
