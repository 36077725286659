import { ISemanticEvent } from '../ports/in'
import { EventCategories, EventPriorities } from '../core'
import { getSemanticName } from './getSemanticName'

type EventConfig = Omit<ISemanticEvent, 'payload' | 'name'> & {
  nonInteraction?: boolean
  priority?: EventPriorities
  label?: string
  skip?: boolean
}

type EventFactory<T> = {
  eventName: string
  (payload: T): ISemanticEvent<T> & EventConfig & { category: string, priority: EventPriorities, timestamp: number }
}

/**
 * function creates factory for semantic events
 * @param params default event params or name
 * @param mapper function to transform event payload
 * @return SemanticEvent factory
 */
export function declareEvent<T>(
  params: EventConfig,
  mapper?: (payload: T) => T
): EventFactory<T> {
  const name = getSemanticName(params)

  const eventFactory = (
    payload: T,
    overrideParams: Omit<Partial<EventConfig>, 'name'|'subject'|'action'|'object'|'result'> = {}
  ) => {
    return {
      name,
      category: EventCategories.UNKNOWN,
      priority: EventPriorities.NORMAL,
      timestamp: Date.now(),
      ...params,
      ...overrideParams,
      payload: mapper ? mapper(payload) : payload
    }
  }
  eventFactory.eventName = name
  return eventFactory
}
